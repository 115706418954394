import {Injectable, NgZone} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Storage} from '@ionic/storage-angular';
import {Router} from '@angular/router';
import jwt_decode from 'jwt-decode';
import {Observable} from 'rxjs';
import Swal from 'sweetalert2';
import {NativeEventSource, EventSourcePolyfill} from 'event-source-polyfill';
import {FullObservableService} from "../fullObservable/fullObservable.service";

// export const baseUrl = "http://127.0.0.1:8000/api";

 export const baseUrl = "https://fullsecu-backend.gsexpertise.com/api";

@Injectable({
    providedIn: 'root'
})
export class LoginService {

    public localStorage = window.localStorage;
    private headers = new Headers({
        'accept': 'text/event-stream;charset=UTF-8',
        'Access-Control-Allow-Origin': '*'
    });

    constructor(
        private http: HttpClient,
        private storage: Storage,
        private observerService: FullObservableService,
        private router: Router,
        private zone: NgZone) {
        this.init()
    }

    private getEventSource(url: string): EventSource {
        return new EventSource(url);
    }


    getServerSentEvents(url: string): Observable<any> {
        return new Observable(observer => {
            const eventSource = this.getEventSource(url);
            eventSource.onmessage = event => {
                // console.log('event', event);
                this.zone.run(() => observer.next(event));
            };

            // eventSource.onerror = error => {
            //   console.log('Au cas où il n y a pas de mises à jours sur le serveur');
            //   // console.log('error', error);
            //   // console.log('error-request', eventSource,url);
            //   this.zone.run(() => observer.error(error));
            // };
        });
    }

    getServerSentEvent(url: string): Observable<MessageEvent> {
        return new Observable(observer => {
            const eventSource = this.getEventSource(url);

            eventSource.onmessage = event => {
                this.zone.run(() => observer.next(event));
            };
        });
    }

    async init() {
        // If using, define drivers here: await this.storage.defineDriver(/*...*/);
        const _storage = await this.storage.create();
        this.storage = _storage;
        // this.storage.clear()
    }

    login(credentials: any) {
        return this.http.post(`${baseUrl}/login`, credentials);
    }

    public getToken(credentials: any): void {
        this.login(credentials).subscribe({
                next: (result: any) => {
                    this.storage.remove('token');
                    this.storage.set('token', result.token);
                    const token: any = jwt_decode(result.token);
                    if (token) {
                        localStorage.setItem('token', token);
                        if (token) {
                            this.storage.set('email', token.username);
                        }
                    }
                    if (token && token.isBlocked === true) {
                        let message = token.entreprise ? 'Votre entreprise a été bloquée du systéme.' : 'Votre compte a été bloqué du système.';
                        Swal.fire({
                            icon: 'error',
                            title: 'ECHEC',
                            text: message,
                            footer: '<h4>Pour plus d\'infos, veuiller se rapprocher de votre administrateur  ?</h4>'
                        })
                    } else {
                        let tabRoles = ['ROLE_SUPERADMIN', 'ROLE_ADMIN', 'ROLE_GESTIONNAIRE', 'ROLE_AGENT', 'ROLE_CLIENT'];
                        for (const role of tabRoles) {
                            if (token.roles.includes(role)) {
                              //  this.observerService.reloadLogin.emit(true);
                                this.redirectByRole(role);
                                break;
                            }
                        }
                    }
                    // localStorage.setItem('token', result.token);
                },
                error: err => {
                    this.storage.remove('token');
                    console.log("error", err);
                }
            }
        );
    }

    getStoredToken() {
        return this.storage.get('token')
        // return this.localStorage.getItem('token');
    }

    deconnected() {
        this.observerService.reloadLogin.emit(false);
        this.storage.remove('token');
        this.storage.clear();
        this.router.navigate(['home']);
        //  this.observerService.reloadUser.emit(true);
    }

    deleteToken() {
        this.storage.remove('token');
    }

    connected() {
        return !!this.storage.get('token');
    }

    async decodeToken() {
        // console.log("decodeeeed",await this.storage.get('token') ? jwt_decode(await this.storage.get('token')) : null);
        return await this.storage.get('token') ? jwt_decode(await this.storage.get('token')) : undefined;
    }

    async getCodeVerification() {
        return await this.storage.get('codeVerification');
    }

    async getConncted() {
        const token: any = await this.decodeToken();
        return (token && token.roles && token.roles.length > 0 && token.roles[0]) ? token.roles[0] : undefined;
        //   return token.roles[0]
    }

    async getRoles() {
        const token: any = await this.decodeToken();
        return (token && token.roles && token.roles) ? token.roles : undefined;
        //  return token.roles;
    }

    async getUserInfos() {
        const token: any = await this.decodeToken();
        return (token && token.username && token.username) ? token.username : undefined;

        //  return token.username;
    }

    redirectByRole(role: string) {
        this.observerService.reloadLogin.emit(true);
        switch (role) {
            case 'ROLE_ADMIN': {
                //localStorage.clear() ;
                this.router.navigate(['admin/home']).then(() => {
//   this.observerService.reloadLogin.emit(true);
                    window.location.reload();
                });
                break;
            }
            case 'ROLE_SUPERADMIN': {
                this.router.navigate(['super-admins']).then(() => {
                    window.location.reload();
                    this.observerService.reloadLogin.emit(true);
                });
                break
            }
            case 'ROLE_GESTIONNAIRE': {
                this.router.navigate(['gestionnaire']).then(() => {
                    window.location.reload();
                    this.observerService.reloadLogin.emit(true);
                });
                /*   this.router.navigateByUrl('/').then(() => {
                       this.router.navigate([`gestionnaire`]).then(() => {
                           this.observerService.reloadLogin.emit(true);
                       });
                   });*/
                break
            }
            case 'ROLE_AGENT': {
                this.router.navigate(['agent']).then(() => {
                    window.location.reload();
                    this.observerService.reloadLogin.emit(true);
                });
                break
            }
            case 'ROLE_CLIENT': {
                this.router.navigate(['client']).then(() => {
                    window.location.reload();
                    this.observerService.reloadLogin.emit(true);
                });
                break
            }
        }
        //   location.reload();
    }


    mercure() {
        let url = new URL('http://localhost:9000/.well-known/mercure');
        // // Add topic to listen to
        url.searchParams.append('topic', 'push');
        /*   url.searchParams.append('topic', 'book');
           url.searchParams.append('topic', 'block');
           // console.log(url.href);
           url.searchParams.append('topic', 'CONGE_NOTOFICATION');
           url.searchParams.append('topic', 'PLANNING_NOTIFICATION');
           url.searchParams.append('topic', 'blockEnterprise');
           url.searchParams.append('topic', 'blockEnterprise');*/

        const eventSource = new EventSource(url.toString());
        if (eventSource) {
            // const eventSource = new EventSource(`http://localhost:8000/hub?topic=http://${endpoint}+'/api/users/44'`);
            // The callback will be called every time an update is published
            eventSource.onmessage = e => {
                console.log(JSON.parse(e.data));
                let data = JSON.parse(e.data);
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Your work has been saved',
                    showConfirmButton: false,
                    timer: 1000
                })
            };
            eventSource.onerror = e => {
                console.log("erreur", e);
            }
        }

    }


    sseLast() {
        this.getServerSentEvents('http://localhost:9000/.well-known/mercure?topic=push').subscribe(
            (data) => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'deleted with success',
                    showConfirmButton: false,
                    timer: 1000
                })

            },
            (error) => {
                console.log('erreur est là');
                console.log(error);
            }
        );
    }

    sse() {
        const optionRequete = {
            headers: new HttpHeaders({
                'mon-entete-personnalise': 'maValeur'
            })
        };
        var eventSource = new EventSourcePolyfill('http://localhost:9000/.well-known/mercure?topic=push', {
            headers: {
                'Access-Control-Allow-Origins': '*',
                'Access-Control-Allow-Credentials': 'true',
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZXJjdXJlIjp7InB1Ymxpc2giOlsiKiJdfX0.Q5N0SCJW2C5xXZYMYQ-3Ka--ySS1tfhPxSBgCQY948M'
            }
        });
        eventSource.onmessage = e => console.log(e);
        eventSource.onerror = e => {
            // console.log("erreur",e);
        }
        eventSource.addEventListener('message', (e) => {
            // console.log(e);
        });

        this.getServerSentEvents('http://localhost:9000/.well-known/mercure?topic=push').subscribe(
            (data) => {
                console.log('data');
                console.log(JSON.parse(data.data));

            },
            (error) => {
                // console.log('erreur est là');
                // console.log(error);
            }
        );
    }

}
