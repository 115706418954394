import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseUrl } from '../auth/login.service';


@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  constructor(private http:HttpClient) { }

  getFildeDiscussions(){
    return this.http.get(baseUrl+'/getMessages');
  }

  sendMessage(message:any){
    return this.http.post(baseUrl+'/sendMessage',message);
  }

  createNewDiscussion(discussion:any){
    return this.http.post(baseUrl+'/createFilDiscuss/personnel',discussion);
  }

  createNewGroupDiscussion(discussion:any){
    return this.http.post(baseUrl+'/createFilDiscuss/group',discussion);
  }

  createNewGroupDiscussionNamed(discussion:any){
    return this.http.post(baseUrl+'/createFilDiscuss/newGroup',discussion);
  }
}

