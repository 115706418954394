import {LOCALE_ID, NgModule} from '@angular/core';
import {MaterialModule} from './material.module';
import {CommonsLibComponent} from './commons-lib.component';
import {CommonModule, registerLocaleData} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {RouteReuseStrategy, RouterModule} from '@angular/router';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {NgxPaginationModule} from 'ngx-pagination';
import {EditUserComponent} from './edit-user/edit-user.component';
import {DisplayUserComponent} from './display-user/display-user.component';
import {ConsigneCommonComponent} from './consigne-common/consigne-common.component';
import {AddConsigneCommonComponent} from './add-consigne-common/add-consigne-common.component';
import {ListConsigneCommonComponent} from './list-consigne-common/list-consigne-common.component';
import {EditConsigneCommonComponent} from './edit-consigne-common/edit-consigne-common.component';
import {AddConsigneCommonClientComponent} from './add-consigne-common-client/add-consigne-common-client.component';
import {EditConsigneCommonClientComponent} from './edit-consigne-common-client/edit-consigne-common-client.component';
import {ListNotifsCommonComponent} from './list-notifs-common/list-notifs-common.component';
import {ListMessagesCommonComponent} from './list-messages-common/list-messages-common.component';
import {CreateNewMessageCommonComponent} from './create-new-message-common/create-new-message-common.component';
import {MatDialogModule} from '@angular/material/dialog';
import {TypeCommonCongeComponent} from './type-common-conge/type-common-conge.component';
import {DetailsDemandCommonCongeComponent} from './details-demand-common-conge/details-demand-common-conge.component';
import {EditDemandCommonCongeComponent} from './edit-demand-common-conge/edit-demand-common-conge.component';
import {AddsiteComponent} from "./addsite/addsite.component";
import {ListeCommonSiteComponent} from "./liste-common-site/liste-common-site.component";
import {DisplayCommonProfilComponent} from "./display-common-profil/display-common-profil.component";
import {ServicesLibModule} from '../../../services-lib/src/lib/services-lib.module';
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {TokenInterceptorService} from "../interceptor/token-interceptor.service";
import {AuthentificationService} from "../authentification/authentification.service";

import {LoginService} from '../../../services-lib/src/lib/auth/login.service';
import {ResponsiveService} from '../../../services-lib/src/lib/responsive/responsive.service';
import {FullObservableService} from '../../../services-lib/src/lib/fullObservable/fullObservable.service';
import {GroupeService} from '../../../services-lib/src/lib/groupe/groupe.service';
import {SiteService} from '../../../services-lib/src/lib/site/site.service';
import {EmployesService} from '../../../services-lib/src/lib/employes/employes.service';
import {SuperAdminService} from '../../../services-lib/src/lib/superAdmin/SuperAdmin.service';
import {EntrepriseService} from '../../../services-lib/src/lib/entreprise/entreprise.service';
import {AddCommonPlanningComponent} from "./add-common-planning/add-common-planning.component";
import {PlanningsComponent} from "./plannings/plannings.component";
import {FullCalendarModule} from "@fullcalendar/angular";
import {ScrollPanelModule} from "primeng/scrollpanel";
import {CardModule} from "primeng/card";
import {ButtonModule} from "primeng/button";
import {RippleModule} from "primeng/ripple";
import {TabMenuModule} from "primeng/tabmenu";
import {DialogModule} from "primeng/dialog";
import localeFr from "@angular/common/locales/fr";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import {PlanningPdfComponent} from "./planning-pdf/planning-pdf.component";
import {PanelModule} from "primeng/panel";
import {TableModule} from "primeng/table";
import {PaginationComponent} from "./pagination/pagination.component";
import {BadgeModule} from "primeng/badge";
import {MessagesComponent} from "./messages/messages.component";
import {ListUserComponent} from "./list-user/list-user.component";
import {HeaderComponent} from "./header/header.component";
import {EvenementComponent} from "./evenement/evenement.component";
import {DemandCommonCongeComponent} from "./demand-common-conge/demand-common-conge.component";
import {AddUserComponent} from "./add-user/add-user.component";
import {AddEvenementFormComponent} from "./add-evenement-form/add-evenement-form.component";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {AutocompleteLibModule} from "angular-ng-autocomplete";
import {MenuButtonItemComponent} from "./menu/menu-button-item/menu-button-item.component";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {FlexLayoutModule} from "@angular/flex-layout";
import {InputTextModule} from "primeng/inputtext";
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import {NgxMatMomentModule} from "@angular-material-components/moment-adapter";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {GoogleMapsModule} from "@angular/google-maps";

registerLocaleData(localeFr);

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
    dayGridPlugin,
    interactionPlugin,
    timeGridPlugin
]);

const declarables = [
    CommonsLibComponent,
    EditUserComponent,
    ListNotifsCommonComponent,
    ListMessagesCommonComponent,
    CreateNewMessageCommonComponent,
    TypeCommonCongeComponent,
    DetailsDemandCommonCongeComponent,
    EditDemandCommonCongeComponent,
    AddConsigneCommonComponent,
    AddsiteComponent,
    ConsigneCommonComponent,

    ListConsigneCommonComponent,
    EditConsigneCommonComponent,
    AddConsigneCommonClientComponent,
    EditConsigneCommonClientComponent,
    ListeCommonSiteComponent,
    DisplayCommonProfilComponent,
    DisplayUserComponent,
    AddCommonPlanningComponent,
    PlanningsComponent,
    PlanningPdfComponent,
    PaginationComponent,

    MessagesComponent,
    ListUserComponent,
    HeaderComponent,

    EvenementComponent,
    DemandCommonCongeComponent,
    AddUserComponent,

    AddEvenementFormComponent,

    MenuButtonItemComponent
]

@NgModule({
    declarations: [...declarables,],
    imports: [
        MaterialModule,
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule,
        ReactiveFormsModule,
        NgxDatatableModule,
        NgxPaginationModule,

        FullCalendarModule,
        ScrollPanelModule,
        CardModule,
        ButtonModule,

        RippleModule,
        TabMenuModule,
        DialogModule,

        PanelModule,
        TableModule,

        BadgeModule,

        ServicesLibModule,
        DragDropModule,
        AutocompleteLibModule,
        ProgressSpinnerModule,
        FlexLayoutModule,
        InputTextModule,

        NgxMatTimepickerModule,
        NgxMatDatetimePickerModule,

        NgxMatMomentModule,

        MatDatepickerModule,
        GoogleMapsModule,

    ],
    exports: [...declarables,
        MatDialogModule,
        NgxDatatableModule,
    ],

    providers: [
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true},
        {provide: LOCALE_ID, useValue: 'fr-FR'},
        AuthentificationService,
        EntrepriseService,
        SuperAdminService,
        LoginService,
        ResponsiveService,
        EmployesService,
        FullObservableService,
        GroupeService,
        SiteService
    ],
})
export class CommonsLibModule {
}
