import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'dev-add-evenement-form',
  templateUrl: './add-evenement-form.component.html',
  styleUrls: ['./add-evenement-form.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AddEvenementFormComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
