import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatChipInputEvent} from '@angular/material/chips';
import {MatDialog} from '@angular/material/dialog';
import {AdminService} from '../../../../services-lib/src/lib/admin/admin.service';
import Swal from 'sweetalert2';
import {MessagesService} from '../../../../services-lib/src/lib/messages/messages.service';
import {CreateNewMessageCommonComponent} from '../create-new-message-common/create-new-message-common.component';
import {GroupeService} from '../../../../services-lib/src/lib/groupe/groupe.service';
import {map, startWith} from 'rxjs/operators';
import {LoginService} from '../../../../services-lib/src/lib/auth/login.service';
import {UserService} from '../../../../services-lib/src/lib/user/user.service';


export interface Fruit {
    name: string;
}

class Auteur {
    id: number = 0;
    nom: string = "";
    prenom: string = "";
    email: string = "";
}

class Message {
    id: number = 0;
    message: string = "";
    objet: string = "";
    createdAt: Date = new Date();
    auteur: Auteur | any;
}

class FilDeDiscussion {
    createdAt: Date = new Date();
    id: number = 0;
    titre: string = "Pas de titre";
    type: string = "Pas de type";
    messages: Message[] = [];
}


@Component({
    selector: 'dev-list-messages-common',
    templateUrl: './list-messages-common.component.html',
    styleUrls: ['./list-messages-common.component.scss']
})
export class ListMessagesCommonComponent implements OnInit {


    formMessage: FormGroup | any;
    formNewMessage: FormGroup | any;
    formNewMessagePersonnel: FormGroup | any;

    filDeDiscussions: any[] = [];
    allFildeDiscussions: any[] = [];


    allGroupesUserDiscussions: any[] = [];
    idGroupeUsers: any[] = [];

    idUsersFromGroupe: any[] = [];

    user: any = {};

    clientFound: any;
    employeFound: any;
    clients: any[] = [];
    personnels: any[] = [];
    idClients: any[] = [];
    idPersonnels: any[] = [];

    groupeActif: boolean = false;


    newMessageClientActive: boolean = false;
    newMessagePersonnelActive: boolean = false;

    discussionToDisplay: FilDeDiscussion | any;

    rolesUsers: string[] = [];
    roleAdmin: boolean = false;
    roleSuperAdmin: boolean = false;
    roleGestionnaire: boolean = false;
    roleAgent!: boolean;
    roleClient!: boolean;


    constructor(
        private _loginService: LoginService,
        private userService: UserService,
        private messageService: MessagesService,
        private adminService: AdminService,
        private formBuilder: FormBuilder,
        public dialog: MatDialog,
        private groupeService: GroupeService
    ) {
    }

    async ngOnInit() {

        this.rolesUsers = await this._loginService.getRoles();
        this.roleAdmin = (this.rolesUsers.includes('ROLE_ADMIN'));
        this.roleGestionnaire = (this.rolesUsers.includes('ROLE_GESTIONNAIRE'));
        this.roleSuperAdmin = (this.rolesUsers.includes('ROLE_SUPERADMIN'));
        this.roleAgent = (this.rolesUsers.includes('ROLE_AGENT'));
        this.roleClient = (this.rolesUsers.includes('ROLE_CLIENT'));

        this.formMessage = this.formBuilder.group({
            message: new FormControl('', [Validators.required]),
        });

        this.formNewMessage = this.formBuilder.group({
            bodyMessage: new FormControl('', [Validators.required]),
            objetMessage: new FormControl('', [])
        });

        this.formNewMessagePersonnel = this.formBuilder.group({
            bodyMessage: new FormControl('', [Validators.required]),
            objetMessage: new FormControl('', []),
            titreMessage: new FormControl('', [])
        });

        this.getMessages();
        this.fetchAllGroupesDiscussion();
        this.getUser();
    }

    getUser() {
        this.userService.getUserProfil().subscribe(
            (data: any) => {
                this.user = data;
                console.log('user', this.user);
            }
        );
    }


    resetData() {
        this.getMessages();
        this.formNewMessagePersonnel.reset();
        this.idPersonnels = [];
        this.personnels = [];
        this.idUsersFromGroupe = [];
        this.idGroupeUsers = [];
    }

    SendMessagePersonnel() {
        if (this.formNewMessagePersonnel.valid && this.personnels.length > 0) {
            if (this.idUsersFromGroupe.length > 0) {
                this.idPersonnels = this.idPersonnels.concat(this.idUsersFromGroupe.filter((item) => this.idPersonnels.indexOf(item) < 0))
            }
            let message: any = {
                message: this.formNewMessagePersonnel.value.bodyMessage,
                objet: this.formNewMessagePersonnel.value.objetMessage,
                emails: JSON.stringify(this.idPersonnels),
                type: 1
            };
            if (this.idPersonnels.length == 1) {
                this.messageService.createNewDiscussion(message).subscribe(
                    (data: any) => {

                        this.resetData();
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: 'Message envoyé avec succès',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    },
                    (error: any) => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Echec...',
                            text: 'Veuillez verifier votre formulaire!',
                        })
                    }
                );

            } else if (this.idPersonnels.length > 1 && this.groupeActif) {
                let title = this.formNewMessagePersonnel.value.titreMessage;
                if (title !== "") {
                    message.type = 2;
                    message.titre = title;
                    this.messageService.createNewGroupDiscussionNamed(message).subscribe(
                        (data: any) => {

                            this.resetData();
                            Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: 'Message envoyé avec succès',
                                showConfirmButton: false,
                                timer: 1500
                            })
                        },
                        (error: any) => {
                            Swal.fire({
                                icon: 'error',
                                title: 'Echec...',
                                text: 'Veuillez verifier votre formulaire!',
                            })
                        }
                    );
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Echec...',
                        text: 'Veuillez donner un titre!',
                    })
                }

            } else if (this.idPersonnels.length > 1) {
                this.messageService.createNewGroupDiscussion(message).subscribe(
                    (data: any) => {
                        this.getMessages();
                        console.log("c bon", data);
                        this.formNewMessage.reset();
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: 'Message envoyé avec succès',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    },
                    (error: any) => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Echec...',
                            text: 'Veuillez verifier votre formulaire!',
                        })
                    }
                );
            }
            // this.newMessageClientActive = false;
        } else {
            console.log("pas valide");
        }
    }

    SendMessageClient() {
        if (this.formNewMessage.valid && this.idClients.length > 0) {
            let message: any = {
                message: this.formNewMessage.value.bodyMessage,
                objet: this.formNewMessage.value.objetMessage,
                emails: JSON.stringify(this.idClients),
                type: 1
            };
            if (this.idClients.length == 1) {
                this.messageService.createNewDiscussion(message).subscribe(
                    (data: any) => {

                        this.getMessages();
                        console.log("c bon", data);
                        this.formNewMessage.reset();

                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: 'Message envoyé avec succès',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    },
                    (error: any) => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Echec...',
                            text: 'Veuillez verifier votre formulaire!',
                        })
                    }
                );

            } else if (this.idClients.length > 1) {
                this.messageService.createNewGroupDiscussion(message).subscribe(
                    (data: any) => {
                        this.getMessages();
                        console.log("c bon", data);
                        this.formNewMessage.reset();
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: 'Message envoyé avec succès',
                            showConfirmButton: false,
                            timer: 1500
                        })
                    },
                    (error: any) => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Echec...',
                            text: 'Veuillez verifier votre formulaire!',
                        })
                    }
                );
            }
            // this.newMessageClientActive = false;
        } else {
            console.log("pas valide");
        }
    }

    // fetchClients(){
    //   this.adminService.get().subscribe(
    //     (data:any) =>{
    //       // this.clients = data
    //       console.log('les cliebts',this.clients);
    //     },
    //     err=>{
    //       console.log(err);

    //     }
    //   )
    // }

    fetchAllGroupesDiscussion() {
        this.groupeService.getGroupesDiscussion().subscribe(
            (data: any) => {
                this.allGroupesUserDiscussions = data;
                // console.log("groupediscuss",data);
            }
        );

    }

    searchByMail(email: string) {
        let valide = false;
        let client: any = {};
        this.adminService.getClientByEmail(email).subscribe(
            (data: any) => {
                if (data.etat == "SUCCESS") {
                    valide = true;
                    client = data.data;
                    this.clientFound = client;

                }
            }
        );
    }

    checkEmployeByEmail(email: string) {
        let valide = false;
        let employe: any = {};
        this.adminService.checkEmployeByEmail(email).subscribe(
            (data: any) => {
                if (data.etat == "SUCCESS") {
                    valide = true;
                    employe = data.data;
                    this.employeFound = employe;

                }
            }
        );
    }

    groupActivate() {
        this.groupeActif = !this.groupeActif;
    }

    addOnBlur = true;
    readonly separatorKeysCodes = [ENTER, COMMA] as const;

    emailsClient: any[] = [];


    addEmailClient(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        this.clientFound = null;
        // Add email Client
        if (value) {
            this.adminService.getClientByEmail(value).subscribe(
                (data: any) => {
                    if (data.etat == "SUCCESS") {
                        this.clientFound = data.data;
                        if (!this.clients.some((row: any) => row.name === value)) {
                            this.clients.push({name: value, valid: true});
                            this.idClients.push(this.clientFound.uuser.id);
                        }
                    } else {
                        this.clients.push({name: value, valid: false});
                    }
                }
            );
        }
        // Clear the input value
        event.chipInput!.clear();
    }

    addGroupeUser(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        if (value) {
            let valid = this.allGroupesUserDiscussions.some((row: any) => row.titre === value);
            if (valid) {
                if (!this.idGroupeUsers.some((row: any) => row.name === value)) {
                    let items = this.allGroupesUserDiscussions.filter((row: any) => {
                        return row.titre === value
                    });
                    let item: any = items[0];
                    this.idGroupeUsers.push({id: item.id, name: value, valid: valid});
                    item.membres.forEach((element: any) => {
                        if (this.idUsersFromGroupe.indexOf(element.id) < 0) {
                            this.idUsersFromGroupe.push(element.id);
                        }
                    });
                }
            } else {
                this.idGroupeUsers.push({name: value, valid: valid})
            }
            console.log(this.idGroupeUsers, this.idUsersFromGroupe);
        }
        // Clear the input value
        event.chipInput!.clear();
    }

    addEmailPersonnel(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        this.employeFound = null;
        // Add email Client
        if (value) {
            this.adminService.checkEmployeByEmail(value).subscribe(
                (data: any) => {
                    console.log('emploer', data);
                    if (data.etat == "SUCCESS") {
                        this.employeFound = data.data;
                        if (!this.personnels.some((row: any) => row.name === value)) {
                            this.personnels.push({name: value, valid: true});
                            this.idPersonnels.push(this.employeFound.user.id);
                        }


                    } else {
                        this.personnels.push({name: value, valid: false});
                    }
                }
            );
        }
        // Clear the input value
        event.chipInput!.clear();
    }

    removeEmailClient(email: any): void {
        const index = this.clients.indexOf(email);
        if (index >= 0) {
            this.clients.splice(index, 1);
        }
    }

    removeEmailPersonnel(email: any): void {
        const index = this.personnels.indexOf(email);
        if (index >= 0) {
            this.personnels.splice(index, 1);
        }
    }

    removeGroupeUser(email: any): void {
        const index = this.idGroupeUsers.indexOf(email);
        if (index >= 0) {
            this.idGroupeUsers.splice(index, 1);
        }
    }


    goBack() {
        this.newMessageClientActive = false;
        this.newMessagePersonnelActive = false;
    }

    SendMessage() {

    }


    openDialog() {
        const dialogRef = this.dialog.open(CreateNewMessageCommonComponent);

        dialogRef.afterClosed().subscribe(result => {
            console.log(`Dialog result: ${result}`);
        });
    }

    annuler() {
        this.formMessage.reset();
        this.goBack();
        this.discussionToDisplay = new FilDeDiscussion();
    }

    newMessage() {
        if (this.roleAdmin || this.roleGestionnaire) {
            Swal.fire({
                title: 'Choisir le type de message !!!',
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: 'Message Client',
                denyButtonText: `Message personnel`,
            }).then((result: any) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    // Swal.fire('Saved!', '', 'success')
                    this.newMessageClientActive = true;
                    // this.fetchClients();
                } else if (result.isDenied) {
                    // Swal.fire('Changes are not saved', '', 'info')
                    this.newMessagePersonnelActive = true;
                }
            })
        } else if (this.roleClient) {
            this.newMessagePersonnelActive = true;
        }

    }

    submitForm() {
        if (this.formMessage.valid) {
            console.log(this.formMessage.value);
            this.formMessage.value.idFilDeDiscussion = this.discussionToDisplay.id;
            this.formMessage.value.objet = "Pas d'objet";
            this.messageService.sendMessage(this.formMessage.value).subscribe(
                (data: any) => {
                    this.getMessages();
                    this.discussionToDisplay.messages.push(data.messages);
                    this.formMessage.reset();
                    this.goToBottom();
                },
                err => console.log(err)
            );

        }
    }

    goToBottom() {
        let element: any = document.getElementById("message-scroll");
        element.scrollTop = element.scrollHeight;
    }

    getMessages() {
        this.messageService.getFildeDiscussions().subscribe(
            (data: any) => {
                console.log('on est la');
                console.log(data);
                this.filDeDiscussions = data.filDeDiscussion;
                this.filDeDiscussions = this.filDeDiscussions.sort((a: any, b: any) => {
                    return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
                });
            },
            err => console.log(err)
        )
    }


    displayMessages(item: any) {
        this.newMessageClientActive = false;
        this.newMessagePersonnelActive = false;
        this.discussionToDisplay = item;
    }

    createGroupedMessage(): any {
        this.newMessagePersonnelActive = true;
        this.newMessageClientActive = false;
        this.discussionToDisplay = false;
    }

    getNotifsAlreadyRead(): any {
        this.newMessageClientActive = true;
        this.newMessagePersonnelActive = false;
        this.discussionToDisplay = false;
    }

    getNotifNotRead() {

    }

    getNotifImportant() {

    }

    getNotifHided() {

    }

    getNotifs() {

    }
}
