import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LoginService} from '../../../../services-lib/src/lib/auth/login.service';
import {EmployesService} from '../../../../services-lib/src/lib/employes/employes.service';
import {EntrepriseService} from '../../../../services-lib/src/lib/entreprise/entreprise.service';
import {ResponsiveService} from '../../../../services-lib/src/lib/responsive/responsive.service';

@Component({
    selector: 'dev-consigne-common',
    templateUrl: './consigne-common.component.html',
    styleUrls: ['./consigne-common.component.css']
})
export class ConsigneCommonComponent implements OnInit {
    emailAdmin: any;
    idEntreprise!: number;
    entreprise: any;
    first = 0;
    rows = 2;
    consignes: any[] = [];
    selected!: any;
    detailsBox: boolean = false;
    updateEvenement!: FormGroup;
    disabled!: boolean;
    currentDevice: any

    constructor(private _employeeService: EmployesService, private _enterpriseService: EntrepriseService, private loginService: LoginService,
                private _fb: FormBuilder,
                private _responsive: ResponsiveService,
    ) {
        this.updateEvenement = this._fb.group({
            nom: ['', [Validators.required]],
            description: ['']
        })
    }

    ngOnInit(): void {
        this.currentDevice = this._responsive.getDevice()
        this.getUserConnected()
    }

    getEnterprise() {
        this._enterpriseService.getOne(this.idEntreprise).subscribe(
            (data: any) => {
                console.log('data ===>', data)
                this.entreprise = data;
                this.consignes = this.entreprise.typeConsignes;
            }
        )
    }

    getUserConnected() {
        this.emailAdmin = this.loginService.getUserInfos();
        this._employeeService.searchByMail(this.emailAdmin).subscribe(
            data => {
                this.idEntreprise = data[0].entreprise.id;
                this.getEnterprise();
            }
        )
    }

    toggleView(state: boolean) {
        if (state) {
            this.disabled = true
            if (this.currentDevice == 'mobile' || this.currentDevice == 'tablet') {
                document.getElementById('commadesRow')!.classList.add('d-none')
            } else {
                document.getElementById('content')!.classList.remove('container')
                document.getElementById('content')!.classList.add('container-fluid')
                document.getElementById('commadesRow')!.classList.remove('col-md-12');
                document.getElementById('commadesRow')!.classList.add('col-md-6');
            }
        } else {
            this.detailsBox = false;
            this.disabled = false
            if (this.currentDevice == 'mobile' || this.currentDevice == 'tablet') {
                document.getElementById('commadesRow')!.classList.remove('d-none')
            } else {
                document.getElementById('content')!.classList.remove('container-fluid')
                document.getElementById('content')!.classList.add('container')
                document.getElementById('commadesRow')!.classList.remove('col-md-6');
                document.getElementById('commadesRow')!.classList.add('col-md-12');
            }
        }
    }


    //For prime ng pagination
    next() {
        this.first = this.first + this.rows;
    }

    prev() {
        this.first = this.first - this.rows;
    }

    reset() {
        this.first = 0;
    }

    isLastPage(): boolean {
        return this.consignes ? this.first === (this.consignes.length - this.rows) : true;
    }

    isFirstPage(): boolean {
        return this.consignes ? this.first === 0 : true;
    }

    details(evenement: any, i: any) {
        if (evenement == null && i == null) {
            setTimeout(() => {
                this.toggleView(true);
                this.detailsBox = true;
            }, 500)
        }
        if (this.selected === i) {
            setTimeout(
                () => {
                    this.detailsBox = false;
                    this.selected = undefined;
                    this.toggleView(false);
                },
                500
            );
        } else {
            this.toggleView(true);
            setTimeout(() => {
                this.detailsBox = true;
                this.selected = i;
            }, 500)
        }
    }

    close() {
        this.updateEvenement.reset()
        this.toggleView(false)
        this.getUserConnected()
    }


    submit(evenement: any) {
        // console.log('value ==>', this.updateEvenement.value)
        // return
        if (evenement == null) {
            this.updateEvenement.value.entreprise = "/api/entreprises/" + this.idEntreprise;
            this._employeeService.creatTypeConsigne(this.updateEvenement.value).subscribe(
                data => {
                    console.log('data ===>', data)
                    this.toggleView(false)
                    this.getUserConnected()
                }, err => {
                    console.log('error ==>', err)
                    this.toggleView(false)
                }
            )
        } else {
            this._employeeService.updateTypeConsigne(evenement.id, this.updateEvenement.value).subscribe(
                data => {
                    console.log('data ===>', data)
                    this.toggleView(false)
                    this.getUserConnected()
                }, err => {
                    console.log('error ==>', err)
                    this.toggleView(false)
                }
            )
        }
    }
}
