import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseUrl } from '../auth/login.service';

@Injectable({
  providedIn: 'root'
})
export class GroupeService {

  constructor(private http:HttpClient) { }

  getAllGroupes(){
    return this.http.get(`${baseUrl}/groupes`);
  }

  getAgentsGroupes(id:number){
    return this.http.get(`${baseUrl}/groupes/${id}/agents`);
  }

  postOnePlanning(form:any,id:number){
    return this.http.post(`${baseUrl}/site/${id}/addPlanning`,form);
  }

  getUniqueAgents(form:any){
     return this.http.post(`${baseUrl}/groupes/agentsUnique`,form);
  }

  createGroupeDiscution(form:any){
    return this.http.post(`${baseUrl}/groupe_discussions`,form);
  }

  getGroupesDiscussion(){
    return this.http.get(`${baseUrl}/groupe_discussions`);
  }

}
