import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginService } from '../auth/login.service';
import { baseUrl } from '../auth/login.service';

@Injectable({
  providedIn: 'root'
})
export class EntrepriseService {


token:any;
constructor(private http: HttpClient, private loginService: LoginService) {
 }
httpOptions = {
   headers: new HttpHeaders({
     'Authorization': 'Bearer ' +this.loginService.getStoredToken(),
   })
 };

 getAll(){
    return this.http.get(`${baseUrl}/entreprises`);
 }
  add(data:any): Observable<any> {
    return this.http.post<any>(`${baseUrl}/entreprise/create`, data);
  }
  getOne(id: number){
    return this.http.get(`${baseUrl}/entreprises/${id}`);
  }

  softDelete(id:number){
    return this.http.delete(`${baseUrl}/entreprises/${id}`);
  }
  blockEmployes(id:number){ 
    return this.http.get(`${baseUrl}/entreprises/${id}/blocked`);
  }
  hardDelete(id:number){
    return this.http.delete(`${baseUrl}/entreprise/${id}/deleted`);
  }
  update(id: number, data: any): Observable<any> {
    return this.http.put<any>(`${baseUrl}/entreprises/${id}`, data)
  }
  search(params:any){
    return this.http.get<any>(`${baseUrl}/entreprises?nom=${params}`)
  }

  getConsignesEntreprise(){
    return this.http.get(`${baseUrl}/consignesbyEntreprise`);
  }

  getTypesConsignes(){
    return this.http.get(`${baseUrl}/type_consignes`);
  }

  getTypesConges(){
    return this.http.get(`${baseUrl}/type_conges`);
  }

  getAllConges(){
    return this.http.get(`${baseUrl}/conges`);
  }

  getMyConges(){
    return this.http.get(`${baseUrl}/mesconges`);
  }

  getOneDemandeConge(id:number){
    return this.http.get(`${baseUrl}/conges/${id}`);
  }

  addDemandeConge(form:any,justificatif:any=null){
    const formData: FormData = new FormData();
    formData.append('justificatif', justificatif);
    formData.append('dateDebutSouhaitee', form.dateDebutSouhaitee);
    formData.append('dateFinSouhaitee', form.dateFinSouhaitee);
    formData.append('typeConge', form.typeId);
    formData.append('commentaire', form.commentaire);
    // console.log('form',form,'formdata',formData);
    return this.http.post(`${baseUrl}/conges`, formData);
  }

  updateDemandeConge(form:any,id:number,justificatif:any=null){
    const formData: FormData = new FormData();
    formData.append('justificatif', justificatif);
    formData.append('dateDebutSouhaitee', form.dateDebutSouhaitee);
    formData.append('dateFinSouhaitee', form.dateFinSouhaitee);
    formData.append('typeConge', form.typeId);
    formData.append('commentaire', form.commentaire);
    formData.append('_method','PUT');
    console.log('form',form,'formdata',formData,'id',id);
    return this.http.post(`${baseUrl}/conges/${id}?_method=PUT`, formData);
  }

  traiterDemandeConge(form:any,id:number){
    const formData: FormData = new FormData();
    if (form.resultat===1) {
      formData.append('dateDebutAccordee', form.dateDebutAccordee);
      formData.append('dateFinAccordee', form.dateFinAccordee);
    }
    formData.append('etat', form.etat);
    formData.append('etatResultat', form.resultat);
    formData.append('_method','PUT');
    console.log('form',form,'formdata',formData,'id',id);
    return this.http.post(`${baseUrl}/conges/${id}?_method=PUT`, formData);
  }



}
