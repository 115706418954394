import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseUrl } from '../auth/login.service';

@Injectable({
  providedIn: 'root'
})
export class AgentService {

  constructor(private http: HttpClient, ) { }

  getOnePlanning(id:number){
    return this.http.get(`${baseUrl}/plannings/${id}`)
  }
  createMainCourante(id:number, data:any){
    return this.http.post(`${baseUrl}/planning/${id}/main_courante/create`, data)
  }
  createEvenement(id:number, data:any){
    return this.http.post(`${baseUrl}/main_courante/${id}/evenement/create`, data)
  }
  getConnected(){
    return this.http.get(`${baseUrl}/user/connected`)
  }
  cloturer(id:number){
    return this.http.put(`${baseUrl}/main_courante/${id}/cloture`, null)
  }
  notif(){
    return this.http.get(`${baseUrl}/my_notifications`)
  }
  getUserByEmail(email: string) {
    return this.http.get(`${baseUrl}/users?email=${email}`);
  }
}
