import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {Router} from '@angular/router';
import {FullObservableService} from '../../../../services-lib/src/lib/fullObservable/fullObservable.service';
import {ResponsiveService} from '../../../../services-lib/src/lib/responsive/responsive.service';
import Swal from 'sweetalert2';
import {UserService} from '../../../../services-lib/src/lib/user/user.service';
import {LoginService} from '../../../../services-lib/src/lib/auth/login.service';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}

interface User {
    id: number;
    email: string;
    telephone: string;
    prenom: string;
    nom: string;
    adresse: string;
    ville: string;
    pays: string;
    dateNaissance: string;
}

@Component({
    selector: 'dev-edit-user',
    templateUrl: './edit-user.component.html',
    styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {

    rolesUsers: string[] = [];
    roleAdmin: boolean = false;
    roleAgent: boolean = false;
    roleGestionnaire: boolean = false;
    roleSuperAdmin: boolean = false;
    roleClient: boolean = false;

    panelOpenState = false;
    public errorLogin: boolean = false;
    formGroup: any = FormGroup;
    currentDeviceName: string = "";
    currentUser: any;

    user: User = {
        id: 0,
        email: '',
        telephone: '',
        prenom: '',
        nom: '',
        adresse: '',
        ville: '',
        pays: '',
        dateNaissance: ''
    };

    constructor(
        private _loginService: LoginService,
        private responsive: ResponsiveService,
        private _observableService: FullObservableService,
        private userService: UserService,
        private router: Router
    ) {
    }

    async ngOnInit() {
        this.currentDeviceName = this.responsive.getDevice();
        this.initForm();
        this.getUserProfil();
        this.rolesUsers = await this._loginService.getRoles();
        this.roleAgent = (this.rolesUsers.includes('ROLE_AGENT')) ? true : false;
        this.roleGestionnaire = (this.rolesUsers.includes('ROLE_GESTIONNAIRE')) ? true : false;
        this.roleAdmin = (this.rolesUsers.includes('ROLE_ADMIN')) ? true : false;
        this.roleSuperAdmin = (this.rolesUsers.includes('ROLE_SUPERADMIN')) ? true : false;
        this.roleClient = (this.rolesUsers.includes('ROLE_CLIENT')) ? true : false;
        this.disableFiels();
    }

    getUserProfil() {
        this.userService.getUserProfil().subscribe(
            (data: any) => {
                console.log(data);
                this.currentUser = data;
                this.user = {
                    id: data.id,
                    email: data.email,
                    telephone: data.contact ? data.contact.telephone : '',
                    prenom: data.prenom,
                    nom: data.nom,
                    adresse: data.contact ? data.contact.address : '',
                    ville: data.contact ? data.contact.ville.nom : '',
                    pays: data.contact ? data.contact.ville.pays.nom : '',
                    dateNaissance: data.dateDeNaissance
                };
                this.normalizeString();

            },
            (error) => {
                console.log(error);
            }
        );
    }

    disableFiels() {
        if (this.roleAgent && !this.roleGestionnaire && !this.roleAdmin) {
            this.formGroup.controls['prenom'].disable();
            this.formGroup.controls['nom'].disable();
            this.formGroup.controls['dateNaiss'].disable();
        }
    }

    getProfil() {
        if (this.roleAdmin) {
            this.router.navigate(['/admin/users/12/profil']);
        } else if (this.roleGestionnaire) {
            this.router.navigate(['/gestionnaire/users/12/profil']);
        } else if (this.roleSuperAdmin) {
            this.router.navigate(['/super-admins/users/12/profil']);
        } else if (this.roleAgent) {
            this.router.navigate(['/agent/users/12/profil']);
        } else if (this.roleClient) {
            this.router.navigate(['/client/users/12/profil']);
        }
    }

    initForm() {
        console.log(this.user);
        this.formGroup = new FormGroup({
            prenom: new FormControl('', [Validators.required]),
            nom: new FormControl('', [Validators.required]),
            email: new FormControl('', [Validators.required]),
            telephone: new FormControl('', [Validators.required]),
            password: new FormControl('', []),
            confirm: new FormControl('', []),
            dateNaiss: new FormControl('', [Validators.required]),
            nomPays: new FormControl('', [Validators.required]),
            nomVille: new FormControl('', [Validators.required]),
            adresse: new FormControl('', [Validators.required])
        })
    }

    submitForm() {
        console.log(this.formGroup.value);
        if (this.formGroup.valid) {
            let userUpdate: any = {
                "email": this.formGroup.value.email,
                "nom": this.formGroup.value.nom,
                "prenom": this.formGroup.value.prenom,
                "dateDeNaissance": this.formGroup.value.dateNaiss,
                "contact": {
                    "telephone": this.formGroup.value.telephone,
                    "ville": {
                        "nom": this.formGroup.value.nomVille,
                        "pays": {
                            "nom": this.formGroup.value.nomPays
                        }
                    },
                    "address": this.formGroup.value.adresse
                },
            }
            if (this.formGroup.value.password === this.formGroup.value.confirm && this.formGroup.value.password.length > 0) {
                userUpdate = {...userUpdate, "password": this.formGroup.value.password};
            }
            this.userService.updateUser(this.user.id, userUpdate).subscribe(
                (result: any) => {
                    if (result) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: 'Informations modifiées avec success',
                            showConfirmButton: false,
                            timer: 1000
                        })
                        this._observableService.reloadUser.emit(true);
                    }
                },
                (error: any) => {
                    console.log(error);
                })
        }
    }

    normalizeString() {
        this.user.nom = this.user.nom.toUpperCase();
        this.user.prenom = this.user.prenom.charAt(0).toUpperCase() + this.user.prenom.slice(1);
    }


    hide() {
        this.errorLogin = false;
    }

    selected = new FormControl('valid', [
        Validators.required,
        Validators.pattern('valid'),
    ]);

    selectFormControl = new FormControl('valid', [
        Validators.required,
        Validators.pattern('valid'),
    ]);

    nativeSelectFormControl = new FormControl('valid', [
        Validators.required,
        Validators.pattern('valid'),
    ]);

    matcher = new MyErrorStateMatcher();


}
