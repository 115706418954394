import {formatDate} from '@angular/common';
import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {ActivatedRoute, Router} from '@angular/router';
import {EntrepriseService} from '../../../../services-lib/src/lib/entreprise/entreprise.service';
import {Observable} from 'rxjs/internal/Observable';
import {map, startWith} from 'rxjs/operators';
import Swal from 'sweetalert2';
import {LoginService} from '../../../../services-lib/src/lib/auth/login.service';

class Auteur {
    id: number = 0;
    nom: string = '';
    prenom: string = '';
    email: string = '';
    dateDeNaisance = null;
}

class TypeConge {
    id: number = 0;
    titre: string = '';
    description: string = '';
}


class DemandeConge {
    id: number = 0;
    commentaire: string = '';
    auteur: Auteur = new Auteur();
    dateDebutSouhaitee: string = '';
    dateFinSouhaitee: string = '';
    etat: number = 0;
    typeConge: TypeConge = new TypeConge();
    etatResultat: number = 0;
    justifcatif: Blob = new Blob();
}

@Component({
    selector: 'dev-edit-demand-common-conge',
    templateUrl: './edit-demand-common-conge.component.html',
    styleUrls: ['./edit-demand-common-conge.component.scss']
})
export class EditDemandCommonCongeComponent implements OnInit {


    types: any[] = [];
    typeChoosed: any;

    demandeConge: any;

    validType = true;
    validDebut = true;
    validFin = true;
    validJusificatif: boolean = true;
    fileimported: boolean = false;

    newDemandeActive: boolean = false;
    listDemandesActive: boolean = true;
    mesDemandesActive: boolean = false;

    rolesUsers: string[] = [];
    roleAdmin: boolean = false;
    roleSuperAdmin: boolean = false;
    roleGestionnaire: boolean = false;
    roleAgent!: boolean;
    roleClient!: boolean;

    justificatif: Blob | any = new Blob();

    demandeCongeForm: FormGroup = new FormGroup({});
    site = new FormControl();
    type = new FormControl();
    typesConge: string[] = [];
    filteredTypes: Observable<string[]> = new Observable<string[]>();
    email = new FormControl();
    listGroupCompetencesDisplay: any;
    dateDebutSouhaitee: any;
    dateFinSouhaitee: any;
    validFormE: any;
    commentaire: any;

    constructor(
        private _fb: FormBuilder,
        private entrepriseService: EntrepriseService,
        private router: Router,
        private route: ActivatedRoute,
        private _loginService: LoginService
    ) {
    }

    async ngOnInit() {
        this.demandeCongeForm = this._fb.group({
            typeId: [''],
            commentaire: [''],
            dateDebutSouhaitee: ['', [Validators.required]],
            dateFinSouhaitee: ['', [Validators.required]],
            justificatif: [''],
        });

        this.rolesUsers = await this._loginService.getRoles();
        this.roleAdmin = (this.rolesUsers.includes('ROLE_ADMIN'));
        this.roleGestionnaire = (this.rolesUsers.includes('ROLE_GESTIONNAIRE'));
        this.roleSuperAdmin = (this.rolesUsers.includes('ROLE_SUPERADMIN'));
        this.roleAgent = (this.rolesUsers.includes('ROLE_AGENT'));
        this.roleClient = (this.rolesUsers.includes('ROLE_CLIENT'));
        if (this.roleAgent && (this.roleAdmin || this.roleGestionnaire)) {
            this.roleAgent = false;
        }
        if (this.roleAgent) {
            this.listDemandesActive = false;
            this.mesDemandesActive = true;
        }

        this.filteredTypes = this.type.valueChanges.pipe(
            startWith(''),
            map(value => this._filter_Types(value)),
        );

        this.getTypesConges();
        this.getDetailsConge();


    }

    private _filter_Types(value: string): string[] {
        const filterValue = value.toLowerCase();
        this.typeChoosen(filterValue);
        return this.typesConge.filter(option => option.toLowerCase().includes(filterValue));
    }

    getDetailsConge() {
        let id = this.route.snapshot.params['id'];
        this.entrepriseService.getOneDemandeConge(id).subscribe(
            (data: any) => {
                this.demandeConge = data;
                this.justificatif = this.demandeConge.justifcatif;
                console.log(data);
                this.getDetailsDemande(data);
            });
    }


    getDetailsDemande(demande: any) {
        // console.log('demande',demande);
        // this.demandeCongeForm.value.commentaire = demande.commentaire;
        // this.demandeCongeForm.value.dateDebutSouhaitee = demande.dateDebutSouhaitee;
        // this.type = demande.typeConge.titre;
        this.demandeCongeForm.patchValue({
            typeId: demande.typeId,
            commentaire: demande.commentaire,
            dateDebutSouhaitee: demande.dateDebutSouhaitee,
            dateFinSouhaitee: demande.dateFinSouhaitee,
            justificatif: demande.justificatif,
        });
        this.type.setValue(demande.typeConge.titre);
        // this.typeChoosen(demande.type.titre);
    }

    typeChoosen(type: string) {
        this.validType = false;
        this.types.forEach((element: any) => {
            if (element.titre === type) {
                this.typeChoosed = element;
                this.validType = true;
            }
        });
    }

    editDemandConge(conge: any) {
        this.router.navigate(['admin/demand-conge/' + conge.id + '/edit']);
        console.log('edit conge', conge);
    }

    getTypesConges() {
        this.entrepriseService.getTypesConges().subscribe(
            (data: any) => {
                this.types = data;
                this.typesConge = data.map((type: any) => type.titre);
                console.log('types consignes', this.typesConge)
            }
        );
    }


    handleFileInput(event: any) {
        this.justificatif = event.target.files?.item(0);
        // if (this.justificatif.size > 1048576) {
        //   Swal.fire({
        //     position: 'top-end',
        //     icon: 'error',
        //     title: 'Le fichier est trop volumineux',
        //     showConfirmButton: false,
        //     timer: 1000
        //   });
        //   this.justificatif = new File([""], "");
        // }
        if (this.justificatif.size > 0) {
            this.fileimported = true;
        }
        console.log(this.justificatif, this.demandeCongeForm.value);
    }

    goBack() {
        this.demandeCongeForm.reset();
        this.justificatif = new File([""], "");
        this.fileimported = false;
        this.type = new FormControl();
    }

    addEvent(type: string, event: any) {
        if (type === 'debut') {
            this.validDebut = event.value !== null ? true : false;
        } else if (type === 'fin') {
            this.validFin = event.value !== null ? true : false;
        }
        console.log(event.value, type);
    }

    submitForm() {
        if (this.demandeCongeForm.valid && this.validType && this.validDebut && this.validFin) {
            this.demandeCongeForm.value.dateDebutSouhaitee = formatDate(this.demandeCongeForm.value.dateDebutSouhaitee, 'yyyy-MM-dd', 'en-US');
            this.demandeCongeForm.value.dateFinSouhaitee = formatDate(this.demandeCongeForm.value.dateFinSouhaitee, 'yyyy-MM-dd', 'en-US');
            console.log('form valide', this.demandeCongeForm.value);
            this.demandeCongeForm.value.typeId = this.typeChoosed.id;
            this.demandeCongeForm.value.justificatif = this.justificatif;
            this.entrepriseService.updateDemandeConge(this.demandeCongeForm.value, this.demandeConge.id, this.justificatif).subscribe(
                (data: any) => {
                    console.log('data', data);
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Mise à jour de la demande congé faite avec success',
                        showConfirmButton: false,
                        timer: 1000
                    });
                    // this.demandeCongeForm.reset();
                },
                (error: any) => {
                    console.log('error', error);
                }
            );
        }
    }

    activateNewDemande() {
        console.log('new demande');
    }

    activateListDemandes() {
        if (this.roleAdmin) {
            this.router.navigate(['admin/demand-conge']);
        } else if (this.roleAgent) {
            this.router.navigate(['agent/demand-conge']);
        } else if (this.roleGestionnaire) {
            this.router.navigate(['gestionnaire/demand-conge']);
        }
    }

    activateMesDemandes() {
        if (this.roleAdmin) {
            this.router.navigate(['admin/demand-conge']);
        } else if (this.roleGestionnaire) {
            this.router.navigate(['gestionnaire/demand-conge']);
        }
    }

}
