import {Component, OnInit, HostListener, ViewEncapsulation, Input} from '@angular/core';
import {jsPDF} from "jspdf";
import {LoginService} from '../../../../services-lib/src/lib/auth/login.service';
import {EmployesService} from '../../../../services-lib/src/lib/employes/employes.service';
import {EntrepriseService} from '../../../../services-lib/src/lib/entreprise/entreprise.service';

@Component({
    selector: 'lib-planning-pdf',
    templateUrl: './planning-pdf.component.html',
    styleUrls: ['./planning-pdf.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class PlanningPdfComponent implements OnInit {
    @Input() planning: any;
    @Input() userPlanning: boolean = false;
    @Input() sitePlanning: boolean = false;
    idEntreprise: any = null;
    entreprise: any
    doc = new jsPDF();
    emailAdmin: any;

    constructor(
        private _service: EntrepriseService,
        private loginService: LoginService,
        private employeService: EmployesService,
    ) {
    }

    ngOnInit(): void {
        this.getAdminEntreprise();
    }

    async getAdminEntreprise() {
        this.emailAdmin = await this.loginService.getUserInfos();
        this.employeService.searchByMail(this.emailAdmin).subscribe(
            data => {
                this.idEntreprise = data[0].entreprise.id;
                this.getEntreprise();
            }
        )
    }

    getEntreprise() {
        if (this.idEntreprise) {
            this._service.getOne(this.idEntreprise).subscribe(
                data => {
                    this.entreprise = data
                    // console.log('entreprise ===>', this.entreprise)
                }
            )

        }
    }

    @HostListener("click", ["$event"])
    print(e: any) {
        window.print();
        e.stopPropagation();
    }

}
