import { Component, Input, OnInit } from '@angular/core';
import {NgxPaginationModule} from 'ngx-pagination';

@Component({
  selector: 'dev-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {

  @Input() public configItem: any;
  @Input() itemlists: any[] = [];
 
  constructor() { 
  }
 
  ngOnInit(): void {}
 
}
