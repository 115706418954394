import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LoginService} from '../../../../services-lib/src/lib/auth/login.service';
import {FullObservableService} from '../../../../services-lib/src/lib/fullObservable/fullObservable.service';
import {UserService} from '../../../../services-lib/src/lib/user/user.service';

interface User {
    id?: number;
    email: string;
    telephone: string;
    prenom: string;
    nom: string;
    adresse: string;
    ville: string;
    pays: string;
    dateNaissance: string;
}

@Component({
    selector: 'dev-display-user',
    templateUrl: './display-user.component.html',
    styleUrls: ['./display-user.component.css']
})
export class DisplayUserComponent implements OnInit {

    roleAdmin: boolean = false;
    roleSuperAdmin: boolean = false;
    roleGestionnaire: boolean = false;
    roleAgent!: boolean;
    roleClient!: boolean;
    rolesUsers: string[] = [];

    user: any = {
        email: '',
        telephone: '',
        prenom: '',
        nom: '',
        adresse: '',
        ville: '',
        pays: '',
        dateNaissance: ''
    };
    currentDeviceName: any;

    constructor(private router: Router,
                private userService: UserService,
                private _fullObservable: FullObservableService,
                private _loginService: LoginService) {
    }

    async ngOnInit() {
        this.rolesUsers = await this._loginService.getRoles();
        this.roleAdmin = (this.rolesUsers.includes('ROLE_ADMIN'));
        this.roleGestionnaire = (this.rolesUsers.includes('ROLE_GESTIONNAIRE'));
        this.roleSuperAdmin = (this.rolesUsers.includes('ROLE_SUPERADMIN'));
        this.roleAgent = (this.rolesUsers.includes('ROLE_AGENT'));
        this.roleClient = (this.rolesUsers.includes('ROLE_CLIENT'));


        this.getUserProfil();
        this._fullObservable.reloadUser.subscribe(
            (resolve) => {
                this.getUserProfil();
            }
        )
    }


    editProfil() {
        if (this.roleAdmin) {
            this.router.navigate([`admin/users/${this.user.id}/edit/profil`]);
        } else if (this.roleGestionnaire) {
            this.router.navigate([`gestionnaire/users/${this.user.id}/edit/profil`]);
        } else if (this.roleSuperAdmin) {
            this.router.navigate([`super-admins/users/${this.user.id}/edit/profil`]);
        } else if (this.roleAgent) {
            this.router.navigate([`agent/users/${this.user.id}/edit/profil`]);
        } else if (this.roleClient) {
            this.router.navigate([`client/users/${this.user.id}/edit/profil`]);
        }
    }

    getUserProfil() {
        this.userService.getUserProfil().subscribe(
            (data: any) => {
                this.user = {
                    id: data.id,
                    email: data.email,
                    telephone: data.contact ? data.contact.telephone : '',
                    prenom: data.prenom,
                    nom: data.nom,
                    adresse: data.contact ? data.contact.address : '',
                    ville: data.contact ? data.contact.ville.nom : '',
                    pays: data.contact ? data.contact.ville.pays.nom : '',
                    dateNaissance: data.dateDeNaissance
                };
                this.normalizeString();

            },
            (error) => {
                console.log(error);
            }
        );
    }

    normalizeString() {
        this.user.nom = this.user.nom.toUpperCase();
        this.user.prenom = this.user.prenom.charAt(0).toUpperCase() + this.user.prenom.slice(1);
    }


}
