import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {LoginService} from "../../../../projects/services-lib/src/lib/auth/login.service";
import Swal from "sweetalert2";
import {UserService} from '../../../services-lib/src/lib/user/user.service';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from "@angular/material/snack-bar";
import {ActivatedRoute, Router} from "@angular/router";
import {ResponsiveService} from '../../../services-lib/src/lib/responsive/responsive.service';
import {AgentService} from './../../../services-lib/src/lib/agents/agent.service';
import {NotifsService} from '../../../services-lib/src/lib/notifications/notifs.service';
import {FullObservableService} from '../../../services-lib/src/lib/fullObservable/fullObservable.service';
import {Storage} from "@ionic/storage";
import {Subscription} from "rxjs";
import {MediaObserver} from "@angular/flex-layout";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {

  menuSuperAdmin: any[] = [
    {title: 'Accueil', icon: 'dashboard', index: 'homme', path: 'super-admins'},
    {title: 'Entreprises', icon: 'domain', index: 'entreprise', path: '/super-admins/list-entreprise'},
    {title: 'Pays', icon: 'flag', index: 'pays', path: '/super-admins/parametre/list-pays'},
    {title: 'Utilisateurs', icon: 'settings', index: 'pays', path: '/super-admins/users'},
    {title: 'Messages', icon: 'dashboard', index: 'message', path: '/super-admins/messages'}
  ]

  menuManager: any[] = [
    {title: 'Accueil', icon: 'dashboard', index: 'homme', path: '/gestionnaire'},
    {title: 'Clients', icon: 'person', index: 'client', path: '/gestionnaire/list-clients'},
    {title: 'Employes', icon: 'group', index: 'employe', path: '/gestionnaire/list-employes'},
    {title: 'Groupes', icon: 'manage_accounts', index: 'groupe', path: '/gestionnaire/groupes'},
    {title: 'Statistiques', icon: 'analytics', index: 'stats', path: '/gestionnaire/statistiques'},

    {title: 'Sites', icon: 'satellite', index: 'site', path: '/gestionnaire/list-sites'},
    {title: 'Plannings', icon: 'calendar_month', index: 'planning', path: '/gestionnaire/plannings'},
    {title: 'Consignes', icon: 'document_scanner', index: 'consign', path: '/gestionnaire/consignes'},

    {title: 'Congés', icon: 'folder_shared', index: 'conge', path: '/gestionnaire/demand-conge'},
    {title: 'Main Courantes', icon: 'note_add', index: 'message', path: '/gestionnaire/main-courantes'},

    {title: 'Mon entreprise', icon: 'domain', index: 'entreprise', path: '/gestionnaire/gestion-entreprise'},
  ]

  menuAdmin: any[] = [
    {title: 'Accueil', icon: 'dashboard', index: 'homme', path: '/admin'},
    {title: 'Clients', icon: 'person', index: 'client', path: '/admin/list-clients'},
    {title: 'Employes', icon: 'group', index: 'employe', path: '/admin/list-employe'},
    {title: 'Groupes', icon: 'manage_accounts', index: 'groupe', path: '/admin/groupes'},
    {title: 'Sites', icon: 'satellite', index: 'site', path: '/admin/list-sites'},
    {title: 'Statistiques', icon: 'analytics', index: 'stats', path: '/admin/statistiques'},
    {title: 'Plannings', icon: 'calendar_month', index: 'planning', path: '/admin/plannings'},
    {title: 'Consignes', icon: 'document_scanner', index: 'consign', path: '/admin/consignes'},

    {title: 'Congés', icon: 'note_add', index: 'conge', path: '/admin/demand-conge'},
    {
      title: 'Main Courantes',
      icon: 'format_list_bulleted_add',
      index: 'message',
      path: '/admin/main-courantes'
    }, {title: 'Mon entreprise', icon: 'apartment', index: 'entreprise', path: '/admin/gestion-entreprise'},
  ]

  menuAgent: any[] = [
    {title: 'Accueil', icon: 'dashboard', index: 'homme', path: '/agent'},
    {title: 'Plannings', icon: 'calendar_month', index: 'planning', path: '/agent/plannings'},
    {title: 'Congés', icon: 'folder_shared', index: 'conge', path: '/agent/demand-conge'},
    {title: 'Main Courantes', icon: 'dashboard', index: 'message', path: '/agent/main-courantes'},
    {title: 'Statistiques', icon: 'analytics', index: 'stats', path: '/agent/statistiques'},
    {title: 'Paiements', icon: 'analytics', index: 'paiement', path: '/agent/paiements'},
  ]

  menuClient: any[] = [
    {title: 'Accueil', icon: 'dashboard', index: 'homme', path: '/client'},
    {title: 'Plannings', icon: 'calendar_month', index: 'planning', path: '/client/plannings'},
    {title: 'Sites', icon: 'satellite', index: 'site', path: '/client/sites'},
    {title: 'Statistiques', icon: 'analytics', index: 'stats', path: '/client/statistiques'},
    {title: 'Consignes', icon: 'document_scanner', index: 'consign', path: '/client/consignes'},
    {title: 'Paiements', icon: 'payments', index: 'site', path: '/client/paiements'},
    {title: 'Messages', icon: 'message', index: 'message', path: '/client/messages'},
  ]

  manageParams: any[] = [
    {
      title: 'Type d\'evenements',
      icon: 'settings_slow_motion',
      index: 'tevent',
      path: '/admin/evenements',
      path2: '/gestionnaire/evenements'
    },
    {
      title: 'Type congés',
      icon: 'settings_slow_motion',
      index: 'tconge',
      path: '/admin/type-conge',
      path2: '/gestionnaire/type-conge'
    },
    {
      title: 'Type consigne',
      icon: 'settings_slow_motion',
      index: 'tcons',
      path: '/admin/typeConsignes',
      path2: '/gestionnaire/typeConsignes'
    },
  ]
  navItems: any[] = [];
  public prenom: any;
  public nom: any;
  @Input() deviceName = '';
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  @Input() roles: any;
  notifs: any
  email = '';
  rolesUsers: string[] = [];
  @Input() roleAdmin: boolean = false;
  @Input() roleSuperAdmin: boolean = false;
  @Input() roleGestionnaire: boolean = false;
  @Input() roleAgent!: boolean;
  @Input() roleClient!: boolean;
  @Input() role: any = '';
  @Input() activeMenu: any = '';
  currentDeviceName: string = "";
  token: any;
  numberNotification: number = 0;
  apiURLMercure: string = "http://localhost:9000/.well-known/mercure";
  @Input() isHome = false;

  public isIpad = false;
  public isMobileTablete = false;
  public progress = false;
  public watcher: Subscription = new Subscription();
  isWeb = true;
  constructor(private loginService: LoginService,
              private router: Router,
              private responsive: ResponsiveService,
              private agentService: AgentService,
              private notifService: NotifsService,
              private fullObservable: FullObservableService,
              private _snackBar: MatSnackBar,
              private userServ: UserService,
              private storage: Storage,
              private route: ActivatedRoute,
              public mediaService: MediaObserver,
              private userService: UserService) {
    //  this.loginService.sseLast();
    // this.loginService.mercure();
    //  this.loginService.sse();
    this.fullObservable.reloadLogin.subscribe(
      (result) => {
        console.log(result);
        this.roleSuperAdmin = false;
        this.roleAgent = false;
        this.roleAdmin = false;
        this.roleClient = false;
        this.roleGestionnaire = false;
        this.roles = undefined;
        if(result === true){
          this.init();
        }
      }
    );
  }

  ngOnInit() {
    // @ts-ignore
    this.watcher = this.mediaService._media$.subscribe((change: MediaChange) => {
      this.isIpad = this.responsive.onMediaChange(this.mediaService) || this.mediaService.isActive('lt-lg');
      this.isWeb = this.responsive.onMediaChange(this.mediaService) || this.mediaService.isActive('lt-lg');
      this.isMobileTablete = this.responsive.onMediaChange(this.mediaService);
    });
    this.progress = true;
    this.init();
    this.fullObservable.reloadNotifs.subscribe(
      (data) => {
        this.getNotifs();
      }
    );
    this.notifService.sync().subscribe();
  }

  async init() {
    this.roles = await this.loginService.getConncted();
    this.rolesUsers = await this.loginService.getRoles();
    if (this.roles) {
      this.getUserInfos();
      this.getNotifs();
      this.userService.getUserProfil().subscribe(
        (profil: any) => {
          console.log(profil);
          if (profil) {
            this.prenom = profil.prenom;
            this.nom = profil.nom;
          }
        }
      );
      if (this.roles) {
        switch (this.roles) {
          case 'ROLE_ADMIN': {
            this.navItems = this.menuAdmin;
            this.roleAdmin = true;
            break;
          }
          case 'ROLE_SUPERADMIN': {
            this.navItems = this.menuSuperAdmin;
            this.roleSuperAdmin = true;
            break;
          }
          case 'ROLE_GESTIONNAIRE': {
            this.navItems = this.menuManager;
            this.roleGestionnaire = true;
            break;
          }
          case 'ROLE_AGENT': {
            this.navItems = this.menuAgent;
            this.roleAgent = true;
            break;
          }
          case 'ROLE_CLIENT': {
            this.navItems = this.menuClient;
            this.roleClient = true;
            break;
          }
        }

      }
      // this.getListNotifs();
    }
    this.progress = false;
  }

  getNotifs() {
    this.notifService.getNotifications().subscribe(
      (data: any) => {
        if (data && data.length > 0) {
          let newNotifs: any[] = data.filter((notif: any) => notif.etat == 1);
          this.numberNotification = newNotifs.length;
        }
      }
    )
  }

  getUserInfos() {
    const email = localStorage.getItem('email');
    if (email) {
      this.userService.getUserByEmail(email).subscribe(
        (data: any) => {
          if (data && data.length > 0 && data[0]) {
            this.prenom = data[0].prenom;
            this.nom = data[0].nom;
          }

        });
    }
  }


  loggout() {
    this.loginService.deconnected();
  }

  getListNotifs() {
    if (this.roleAdmin) {
      this.router.navigate(['/admin/notifications']);
    } else if (this.roleGestionnaire) {
      this.router.navigate(['/gestionnaire/notifications']);
    } else if (this.roleAgent) {
      this.router.navigate(['/agent/notifications']);
    } else if (this.roleClient) {
      this.router.navigate(['/client/notifications']);
    }
  }

  getProfil() {
    if (this.roleAdmin) {
      this.router.navigate(['/admin/users/12/profil']);
    } else if (this.roleGestionnaire) {
      this.router.navigate(['/gestionnaire/users/12/profil']);
    } else if (this.roleSuperAdmin) {
      this.router.navigate(['/super-admins/users/12/profil']);
    } else if (this.roleAgent) {
      this.router.navigate(['/agent/users/12/profil']);
    } else if (this.roleClient) {
      this.router.navigate(['/client/users/12/profil']);
    }
  }

  getMessages() {
    if (this.roleAdmin)
      this.router.navigate(["admin/messages"]);
    if (this.roleGestionnaire) {
      this.router.navigate(["gestionnaire/messages"]);
    } else if (this.roleAgent) {
      this.router.navigate(["agent/messages"]);
    } else if (this.roleClient) {
      this.router.navigate(["client/messages"]);
    }
  }

  ngAfterViewInit(): void {
  }

}
