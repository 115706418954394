import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../../../../services-lib/src/lib/auth/login.service';
import { EmployesService } from '../../../../services-lib/src/lib/employes/employes.service';
import { EntrepriseService } from '../../../../services-lib/src/lib/entreprise/entreprise.service';
import { ResponsiveService } from '../../../../services-lib/src/lib/responsive/responsive.service';

@Component({
  selector: 'dev-type-common-conge',
  templateUrl: './type-common-conge.component.html',
  styleUrls: ['./type-common-conge.component.css']
})
export class TypeCommonCongeComponent implements OnInit {

  emailAdmin: any;
  idEntreprise!: number;
  entreprise: any;
  first = 0;
  rows = 3;
  typeConges:any[] = [];
  selected!: any;
  detailsBox: boolean = false;
  updateEvenement: FormGroup = new FormGroup({});
  disabled!: boolean;
  currentDevice :any

  constructor(
      private _employeeService: EmployesService,
      private _enterpriseService: EntrepriseService,
      private loginService:LoginService,
      private _fb: FormBuilder,
      private _responsive: ResponsiveService,
  ) {
   
  }

  ngOnInit(): void {
    this.updateEvenement = this._fb.group({
      titre: ['', [Validators.required]],
      description: ['']
    })
    this.currentDevice = this._responsive.getDevice()
    this.getUserConnected();
  
  }
  getEnterprise(id:number){
    this._enterpriseService.getOne(id).subscribe(
      (data:any)=>{
        console.log('data ===>', data)
        this.entreprise= data;
        this.typeConges = this.entreprise.typeConges;
        console.log('type conges ===>', this.typeConges)
      }
    )
  }
  getUserConnected(){
    this.emailAdmin = this.loginService.getUserInfos();
    this._employeeService.searchByMail(this.emailAdmin).subscribe(
      data=>{
        this.idEntreprise = data[0].entreprise.id;
        console.log('id entreprise ===>', data)
         this.getEnterprise( this.idEntreprise);
      }
    )
  }
  toggleView(state:boolean) {
    if (state){
      this.disabled = true
      if(this.currentDevice == 'mobile' || this.currentDevice =='tablet'){
        document.getElementById('commadesRow')!.classList.add('d-none')
      }
      else{
        document.getElementById('content')!.classList.remove('container')
        document.getElementById('content')!.classList.add('container-fluid')
        document.getElementById('commadesRow')!.classList.remove('col-md-12');
        document.getElementById('commadesRow')!.classList.add('col-md-6');
      }
    }else{
      this.detailsBox = false;
      this.disabled = false
      if(this.currentDevice == 'mobile' || this.currentDevice =='tablet'){
        document.getElementById('commadesRow')!.classList.remove('d-none')
      }
      else{
        document.getElementById('content')!.classList.remove('container-fluid')
        document.getElementById('content')!.classList.add('container')
        document.getElementById('commadesRow')!.classList.remove('col-md-6');
        document.getElementById('commadesRow')!.classList.add('col-md-12');
      }
    }
  }


  //For prime ng pagination
  next() {
    this.first = this.first + this.rows;
  }

  prev() {
      this.first = this.first - this.rows;
  }

  reset() {
      this.first = 0;
  }

  isLastPage(): boolean {
      return this.typeConges ? this.first === (this.typeConges.length - this.rows): true;
  }

  isFirstPage(): boolean {
      return this.typeConges ? this.first === 0 : true;
  }

  details(evenement:any, i:any){
    if (evenement==null && i ==null) {
      setTimeout(() => {
        this.toggleView(true);
      this.detailsBox = true;
      },500)
    }
    if(this.selected === i){
      setTimeout(
        () => {
          this.detailsBox = false;
          this.selected = undefined;
          this.toggleView(false);          
        },
        500
      );
    }
    else{
      this.toggleView(true);
      setTimeout(() => {
      this.detailsBox = true;
      this.selected = i;
      },500)
    }
  }

  close(){
    this.updateEvenement.reset()
    this.toggleView(false)
    this.getUserConnected()
  }


  submit(evenement:any){
    // console.log('value ==>', this.updateEvenement.value)
    // return
    if (evenement == null) {
      this.updateEvenement.value.entreprise = "/api/entreprises/"+this.idEntreprise;
      this.updateEvenement.value.archived = false;
      this._employeeService.creatTypeConge(this.updateEvenement.value).subscribe(
        data => {
          console.log('data ===>', data )
          this.toggleView(false)
          this.getUserConnected()
        }, err => {
          console.log('error ==>', err )
          this.toggleView(false)
        }
      )
    }
    else{
      this._employeeService.updateTypeConge(evenement.id, this.updateEvenement.value).subscribe(
        data => {
          console.log('data ===>', data )
          this.toggleView(false)
          this.getUserConnected()
        }, err => {
          console.log('error ==>', err )
          this.toggleView(false)
        }
      )
    }
  }

}
