import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ResponsiveService} from '../../../../services-lib/src/lib/responsive/responsive.service';
import {LoginService} from '../../../../services-lib/src/lib/auth/login.service';
import Swal from 'sweetalert2';
import {AgentService} from './../../../../services-lib/src/lib/agents/agent.service';
import {NotifsService} from '../../../../services-lib/src/lib/notifications/notifs.service';
import {FullObservableService} from '../../../../services-lib/src/lib/fullObservable/fullObservable.service';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {UserService} from "../../../../services-lib/src/lib/user/user.service";
import {Storage} from "@ionic/storage";
import jwt_decode from 'jwt-decode';


@Component({
    selector: 'lib-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {


    @Input() deviceName = '';
    horizontalPosition: MatSnackBarHorizontalPosition = 'end';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    @Input() roles: any;
    notifs: any
    prenom = '';
    nom = '';
    email = '';
    rolesUsers: string[] = [];
    @Input() roleAdmin: boolean = false;
    @Input() roleSuperAdmin: boolean = false;
    @Input() roleGestionnaire: boolean = false;
    @Input() roleAgent!: boolean;
    @Input() roleClient!: boolean;
    @Input() role: any = '';
    @Input() activeMenu: any = '';
    currentDeviceName: string = "";
    token: any;
    numberNotification: number = 0;
    apiURLMercure: string = "http://localhost:9000/.well-known/mercure";
    @Input() isHome = false;
    page ="manager";

    constructor(
        private _loginService: LoginService,
        private router: Router,
        private responsive: ResponsiveService,
        private agentService: AgentService,
        private notifService: NotifsService,
        private fullObservable: FullObservableService,
        private _snackBar: MatSnackBar,
        private userServ: UserService,
        private storage: Storage,
        private route: ActivatedRoute,

    ) {
    }

    async ngOnInit() {
        this.storage.create();
        this.currentDeviceName = this.responsive.getDevice();
        this.deviceName = this.responsive.getDevice();

        this.roles = await this._loginService.getConncted();
        this.rolesUsers = await this._loginService.getRoles();
        //   this.roleAdmin = (this.rolesUsers && this.rolesUsers.includes('ROLE_ADMIN'));
        //   this.roleGestionnaire = (this.rolesUsers && this.rolesUsers.includes('ROLE_GESTIONNAIRE'));
        //    this.roleSuperAdmin = (this.rolesUsers && this.rolesUsers.includes('ROLE_SUPERADMIN'));
        //   this.roleAgent = (this.rolesUsers && this.rolesUsers.includes('ROLE_AGENT'));
        //  this.roleClient = (this.rolesUsers && this.rolesUsers.includes('ROLE_CLIENT'));
        if (this.roleAgent && (this.roleAdmin || this.roleGestionnaire)) {
            this.roleAgent = false;
        }
        //   await this.sseLast();
        //  await this.sseBlockEntreprise();
        if (this.roleSuperAdmin) {
            this.role = 'superAdmin';
        }
        if (this.roleAdmin) {
            this.role = 'admin';
        }
        if (this.roleGestionnaire) {
            this.role = 'gestionnaire';
        }
        if (this.roleAgent) {
            this.role = 'agent';
        }

        if (this.roleClient) {
            this.role = 'client';
        }


     //   await this.sseNotificationPlanning();
      //  await this.sseNotificationConge();
        this.getNotifs();
        this.fullObservable.reloadNotifs.subscribe(
            (data) => {
                this.getNotifs();
            }
        )

        this.getUserInfos();
        // @ts-ignore
        const url = (this.route && this.route._routerState && this.route._routerState.snapshot && this.route._routerState.snapshot.url) ? this.route._routerState.snapshot.url: undefined
        // @ts-ignore
        console.log(this.route._routerState.snapshot.url);
        if(url && (url === '/super-admins' || url === 'client' || url === '/agent' || url === '/gestionnaire')){
            this.isHome = true;
        }
    }

    getUserInfos() {
        const email = localStorage.getItem('email');
        this.userServ.getUserByEmail(email).subscribe(
            (data: any) => {
                if (data && data.length > 0 && data[0]) {
                    this.prenom = data[0].prenom;
                    this.nom = data[0].nom;
                }

            }
        )
    }

    openSnackBar(message: string) {
        this._snackBar.open(message, 'Fermer', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
        });
    }


    getNotifs() {
        this.notifService.getNotifications().subscribe(
            (data: any) => {
                if (data) {
                    let newNotifs = data.filter((notif: any) => notif.etat == 1);
                    this.numberNotification = newNotifs.length;
                }
            },
            err => {
                throw new Error(err)
            }
        )
    }

    getMessages() {
        if (this.roleAdmin)
            this.router.navigate(["admin/messages"]);
        if (this.roleGestionnaire) {
            this.router.navigate(["gestionnaire/messages"]);
        } else if (this.roleAgent) {
            this.router.navigate(["agent/messages"]);
        } else if (this.roleClient) {
            this.router.navigate(["client/messages"]);
        }
    }

    async sseNotificationPlanning() {
        this.token = await this._loginService.decodeToken();
        this._loginService.getServerSentEvents(`${this.apiURLMercure}?topic=PLANNING_NOTIFICATION`).subscribe(
            (data) => {
                let message = JSON.parse(data.data);
                this.fullObservable.reloadNotifs.emit(true);

                if (message.type === 'ADD_PLANNING_NOTIFICATION' && message.emailUsers.includes(this.token.username)) {
                    this.openSnackBar('Nouvelle notification de planning:AJOUT');
                    this.getNotifs();
                } else if (message.type === 'DELETE_PLANNING_NOTIFICATION' && message.emailAgent === this.token.username) {
                    let texte = message.state ? 'Vous avez été retirés du planning ' + message.title + ' par un administrateur.' : 'Vous avez été résinsérés du planning ' + message.title + ' par un administrateur.';
                    this.openSnackBar('Nouvelle notification de planning:RETRAIT');
                    this.getNotifs();
                } else if (message.type === 'EDIT_PLANNING_NOTIFICATION' && message.emailUsers.includes(this.token.username)) {
                    this.openSnackBar('Nouvelle notification de planning:MODIFICATION');
                    this.getNotifs();
                }
            },
            (error) => {
                throw new Error(error);
            }
        );
    }

    async sseNotificationConge() {
        this.token = await this._loginService.decodeToken();
        this._loginService.getServerSentEvents(`${this.apiURLMercure}?topic=CONGE_NOTOFICATION`).subscribe(
            (data) => {
                let message = JSON.parse(data.data);
                this.fullObservable.reloadNotifs.emit(true);
                if (message.type === 'DEMANDE_DE_CONGE' && message.tabEmailsAdmin.includes(this.token.username)) {
                    this.openSnackBar('Nouvelle notification de demande CONGE');
                    this.getNotifs();
                } else if (message.type === 'DEMANDE_ACCEPTEE' && message.emailUser === this.token.username) {
                    this.openSnackBar('Nouvelle notification de CONGE ACCEPTE');
                    this.getNotifs();
                } else if (message.type === 'DEMANDE_REFUSEE' && message.emailUser === this.token.username) {
                    this.openSnackBar('Nouvelle notification de CONGE REFUSE');
                    this.getNotifs();
                } else if (message.type === 'DEMANDE_ANNULEE' && message.emailUser === this.token.username) {
                    this.openSnackBar('Nouvelle notification de CONGE ANNULE');
                    this.getNotifs();
                } else if (message.type === 'DEMANDE_REPORTEE' && message.emailUser === this.token.username) {
                    this.openSnackBar('Nouvelle notification de CONGE REPORTE');
                    this.getNotifs();
                }
            },
            (error) => {
                throw new Error(error);
            }
        );
    }


    async sseLast() {
        this.token = await this._loginService.decodeToken();
        this._loginService.getServerSentEvents(`${this.apiURLMercure}?topic=block`).subscribe(
            (data) => {
                let message = JSON.parse(data.data);
                if (message.type === 'block' && message.username === this.token.username) {
                    if (message.etat) {
                        this._loginService.deleteToken();
                        Swal.fire({
                            icon: 'error',
                            title: 'MESSAGE',
                            text: 'Votre compte a été définitivement bloqué.',
                            footer: '<h4>Pour avoir plus d\'informations, veuillez contacter le service client de FullSecu</h4>'
                        }).then(() => {
                            this.loggout();
                        });
                    } else {
                        Swal.fire({
                            icon: 'success',
                            title: 'SUCCES',
                            text: 'Votre compte a été débloqué par un administrateur.',
                            footer: '<h4>Pour avoir plus d\'informations, veuillez contacter le service client de FullSecu</h4>'
                        });
                    }
                }
            },
            (error) => {
                console.log('erreur est là');
                console.log(error);
            }
        );
    }

    async sseBlockEntreprise() {
        this.token = await this._loginService.decodeToken();
        this._loginService.getServerSentEvents(`${this.apiURLMercure}?topic=blockEnterprise`).subscribe(
            (data) => {
                let message = JSON.parse(data.data);
                //Verifier si le message est de type blockEnterprise
                if (message.type === 'blockEnterpirse' && message.arrayUsernames.includes(this.token.username)) {
                    if (message.etat) {
                        this._loginService.deleteToken();
                        Swal.fire({
                            icon: 'error',
                            title: 'MESSAGE',
                            text: 'Votre entreprise a été définitivement bloquée.',
                            footer: '<h4>Pour avoir plus d\'informations, veuillez contacter le service client de FullSecu</h4>'
                        }).then(() => {
                            this.loggout();
                        });
                    } else {
                        Swal.fire({
                            icon: 'success',
                            title: 'SUCCES',
                            text: 'Votre entreprise a été débloqué par un administrateur.',
                            footer: '<h4>Pour avoir plus d\'informations, veuillez contacter le service client de FullSecu</h4>'
                        });
                    }
                } else {
                    console.log('nop');
                }
            },
            (error) => {
                console.log('erreur est là');
                console.log(error);
            }
        );
    }


    loggout() {
        this._loginService.deconnected();
    }

    getNotif() {
        if (this.roleAgent) {
            this.agentService.notif().subscribe(
                (data: any) => {
                    this.notifs = data.length;
                },
                err => console.log(err)
            )
        }
    }


    getProfil() {
        if (this.roleAdmin) {
            this.router.navigate(['/admin/users/12/profil']);
        } else if (this.roleGestionnaire) {
            this.router.navigate(['/gestionnaire/users/12/profil']);
        } else if (this.roleSuperAdmin) {
            this.router.navigate(['/super-admins/users/12/profil']);
        } else if (this.roleAgent) {
            this.router.navigate(['/agent/users/12/profil']);
        } else if (this.roleClient) {
            this.router.navigate(['/client/users/12/profil']);
        }
    }

    getAccesConge() {
        if (this.roleAdmin) {
            this.router.navigate(['/admin/demand-conge']);
        } else if (this.roleGestionnaire) {
            this.router.navigate(['/gestionnaire/demand-conge']);
        } else if (this.roleAgent) {
            this.router.navigate(['/agent/demand-conge']);
        }
    }

    gotoEmployes() {
        if (this.roleAdmin) {
            this.router.navigate(['/admin/list-employes']);
        } else if (this.roleGestionnaire) {
            this.router.navigate(['/gestionnaire/list-employes']);
        } else if (this.roleSuperAdmin) {
            this.router.navigate(['/super-admins/list-employes']);
        }
    }

    gotoClients() {
        if (this.roleGestionnaire) {
            this.router.navigate(['/gestionnaire/list-clients']);
        }
        if (this.roleAdmin) {
            this.router.navigate(['/admin/list-clients']);
        }
        if (!this.roleAdmin && !this.roleGestionnaire) {
            this.router.navigate(['/super-admins/list-clients']);
        }
    }

    gotoStats() {
        if (this.roleGestionnaire) {
            this.router.navigate(['/gestionnaire/statistiques']);
        }
        if (this.roleAdmin) {
            this.router.navigate(['/admin/statistiques']);
        }
    }

    addSite() {
        if (this.roleAdmin) {
            this.router.navigate(['/admin/add-site']);
        } else if (this.roleGestionnaire) {
            this.router.navigate(['/gestionnaire/add-site']);
        }
    }

    gotoSite() {
        if (this.roleAdmin) {
            this.router.navigate(['/admin/list-sites']);
        } else if (this.roleGestionnaire) {
            this.router.navigate(['/gestionnaire/list-sites']);
        }
    }

    gotoEntreprise() {
        if (this.roleAdmin) {
            this.router.navigate(['/admin/gestion-entreprise']);
        } else {
            this.router.navigate(['/super-admins/list-entreprise']);
        }


    }

    groupeLink() {
        this.roleGestionnaire ? this.router.navigate(['/gestionnaire/groupes']) : this.router.navigate(['/admin/groupes'])
    }

    planningLink() {
        if (this.roleAgent) {
            this.router.navigate(['/agent'])
            return
        }
        if (this.roleClient) {
            this.router.navigate(['/client'])
            return
        }
        this.roleGestionnaire ? this.router.navigate(['/gestionnaire/plannings']) : this.router.navigate(['/admin/plannings'])

    }

    async gotoHome() {
        let rolesUsers = await this._loginService.getRoles();

        if (this.roleSuperAdmin) {
            this.router.navigate(['/super-admins'])
            return
        }

        if (this.roleAdmin) {
            this.router.navigate(['/admin']);
        } else if (this.roleGestionnaire) {
            this.router.navigate(['/gestionnaire']);
        } else if (this.roleAgent) {
            this.router.navigate(['/agent']);
        } else if (this.roleClient) {
            this.router.navigate(['/client']);
        } else {
            this.router.navigate(['/super-admins']);
        }
    }

    getEvenements() {
        if (this.roleAdmin) {
            this.router.navigate(['/admin/evenements']);
        } else if (this.roleGestionnaire) {
            this.router.navigate(['/gestionnaire/evenements']);
        }
    }

    getConsignes() {
        if (this.roleAdmin) {
            this.router.navigate(['/admin/consignes']);
        } else if (this.roleGestionnaire) {
            this.router.navigate(['/gestionnaire/consignes']);
        } else if (this.roleClient) {
            this.router.navigate(['/client/consignes']);
        }
    }

    getTypeConsignes() {
        if (this.roleAdmin) {
            this.router.navigate(['/admin/typeConsignes']);
        } else if (this.roleGestionnaire) {
            this.router.navigate(['/gestionnaire/typeConsignes']);
        }
    }

    getTypeConges() {
        if (this.roleAdmin) {
            this.router.navigate(['/admin/type-conge']);
        } else if (this.roleGestionnaire) {
            this.router.navigate(['/gestionnaire/type-conge']);
        }
    }

    getListNotifs() {
        if (this.roleAdmin) {
            this.router.navigate(['/admin/notifications']);
        } else if (this.roleGestionnaire) {
            this.router.navigate(['/gestionnaire/notifications']);
        } else if (this.roleAgent) {
            this.router.navigate(['/agent/notifications']);
        } else if (this.roleClient) {
            this.router.navigate(['/client/notifications']);
        }
    }


    getMessagerieEntreprise() {

    }
}
