import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {Storage} from '@ionic/storage';


@Injectable({
    providedIn: 'root'
})
export class AuthentificationService {

    public apiURL = '127.0.0.1:8000/api/';

    constructor(
        private http: HttpClient,
        private storage: Storage,
        private router: Router) {
        this.init();
    }

    async init() {
        this.storage = await this.storage.create();
    }

    login(data: any): Observable<any> {
        return this.http.post(`${this.apiURL}login_check`, data);
    }

    deconnected() {
        this.storage.remove('token');
        this.router.navigate(['home']);
    }

    connected() {
        return !!this.storage.get('token');
    }

    async getToken() {
        await this.storage.get('token').then((data: any) => {
            return data;
        });
    }

}
