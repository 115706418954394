import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { SubscriptionLike } from 'rxjs';
import { apiURL } from 'src/environments/environment';
import { ResponsiveService } from '../../../../../services-lib/src/lib/responsive/responsive.service';

@Component({
  selector: 'login-send-code-verification',
  templateUrl: './send-code-verification.component.html',
  styleUrls: ['./send-code-verification.component.scss'],
})
export class SendCodeVerificationComponent implements OnInit {

  message:string="";
  getMessageError:boolean=false;
  loginForm:FormGroup;
  code:string;
  currentDeviceName:string;
  subscription: SubscriptionLike;

  constructor(
      private formBuilder:FormBuilder,
      private http: HttpClient,
      private router:Router,
      private storage:Storage,
      private responsive:ResponsiveService)
  {

    this.loginForm = this.formBuilder.group(
        {
          codeVerification: ['', [Validators.required]]
        }
    );

    this.init()
  }

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    const _storage = await this.storage.create();
    this.storage = _storage;
    // await this.storage.clear()

  }

  ngOnInit() {
    this.currentDeviceName = this.responsive.getDevice();
    this.responsive.changeDevice(this.responsive.getDevice());
    this.subscription = this.responsive.currentDevice
    .subscribe(
          message => {
              this.currentDeviceName = message;
              console.log('new width'+message);
          })
  }



  async onSubmit(){
    this.code=this.loginForm.value.codeVerification;
    console.log(this.code)
    if(!this.code){
      this.getMessageError=true;
      this.message="Entrez le code de verification";
    }else{
      return this.http.post(`${apiURL}password/reset/${this.code}`,this.loginForm.value)
      .subscribe(
        result=>{
            console.log(result);
            this.storage.set('codeVerification',this.code);
            this.router.navigateByUrl('changePassword');
          },
          error=>{
            console.log('c pas bon du tout')
            console.log(error.error);
            this.getMessageError=true;
            this.message=error.error.message;
            // console.log(error)
          }
        )
    }
  }

  hide(){
    this.getMessageError=false;
  }



}
