import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import Swal from 'sweetalert2';
import {EmployesService} from '../../../../services-lib/src/lib/employes/employes.service';
import {GroupeService} from '../../../../services-lib/src/lib/groupe/groupe.service';

/**
 * @title Drag&Drop disabled sorting
 */

@Component({
    selector: 'dev-create-new-message-common',
    templateUrl: './create-new-message-common.component.html',
    styleUrls: ['./create-new-message-common.component.css']
})
export class CreateNewMessageCommonComponent implements OnInit {

    formAddGroup: FormGroup = new FormGroup({});

    items = ['Carrots', 'Tomatoes', 'Onions', 'Apples', 'Avocados', 'Bananas', 'Lemons', 'Limes', 'Oranges', 'Strawberries'];

    basket = ['Oranges', 'Bananas', 'Cucumbers'];

    employes: any[] = [];
    selectionnes: any[] = [];


    public rows: any[] = [];

    constructor(
        private formBuilder: FormBuilder,
        private employesService: EmployesService,
        private groupesService: GroupeService
    ) {
    }

    ngOnInit(): void {
        this.formAddGroup = this.formBuilder.group({
            titreGroupe: new FormControl('', [Validators.required]),
            descriptionGroupe: new FormControl('', [])
        });
        this.fetchAllEmployes();
    }

    drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex,
            );
        }
    }

    fetchAllEmployes() {
        this.employesService.getAllEmployes().subscribe(
            (data: any) => {
                this.rows = data;
                this.rows.sort((a, b) => a.user.email.toLowerCase() > b.user.email.toLowerCase() ? 1 : -1);
                this.employes = this.rows.map(row => row.user);
                console.log(this.rows, this.employes);
                // this.datasource = data;
            }
        )
    }

    AddNewGroup() {
        let groupe = {
            titre: this.formAddGroup.value.titreGroupe,
            description: this.formAddGroup.value.descriptionGroupe,
            members: JSON.stringify(this.selectionnes.map(row => row.id))
        }
        if (this.formAddGroup.valid) {
            this.groupesService.createGroupeDiscution(groupe).subscribe(
                (data: any) => {
                    console.log(data);
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Message envoyé avec succès',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            )
        }

    }


    // add(event: MatChipInputEvent): void {
    //   const value = (event.value || '').trim();

    //   // Add our fruit
    //   if (value) {
    //     this.fruits.push(value);
    //   }

    //   // Clear the input value
    //   event.chipInput!.clear();

    //   this.fruitCtrl.setValue(null);
    // }

    // remove(fruit: string): void {
    //   const index = this.fruits.indexOf(fruit);

    //   if (index >= 0) {
    //     this.fruits.splice(index, 1);
    //   }
    // }

    // selected(event: MatAutocompleteSelectedEvent): void {
    //   this.fruits.push(event.option.viewValue);
    //   this.fruitInput.nativeElement.value = '';
    //   this.fruitCtrl.setValue(null);
    // }

    // private _filter(value: string): string[] {
    //   const filterValue = value.toLowerCase();

    //   return this.allFruits.filter(fruit => fruit.toLowerCase().includes(filterValue));
    // }

    goBack() {

    }
}
