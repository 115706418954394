import {AdminService} from './../../../../services-lib/src/lib/admin/admin.service';
import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {EntrepriseService} from "../../../../services-lib/src/lib/entreprise/entreprise.service";
import {ResponsiveService} from "../../../../services-lib/src/lib/responsive/responsive.service";
import {SuperAdminService} from "../../../../services-lib/src/lib/superAdmin/SuperAdmin.service";
import Swal from 'sweetalert2'
import {Platform} from '@ionic/angular';
import {LoginService} from '../../../../services-lib/src/lib/auth/login.service';
import {Location} from "@angular/common";

@Component({
    selector: 'add-user',
    templateUrl: './add-user.component.html',
    styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {
    @Input() addClient: boolean = false
    @Input() addEmploye: boolean = false
    @Input() positionClient = true;
    @Input() roleGestionnaire: boolean = false
    @Input() roleAdmin: boolean = false

    isDesktop = false;
    data: any;
    update = false
    superAdmins: number = 0;
    superAd: any;
    id: number = 0
    addForm: FormGroup
    wrong = false
    typeClient = 0
    public placeholder: string = 'Enter the Country Name';
    public keyword = 'nom';
    public historyHeading: string = 'Recently selected';
    public countries: any;
    public villes: any;
    paysAutoComplete = false
    isSelect = false
    currentDeviceName: string | undefined;
    public errorLogin: boolean = false;
    prenom: any;
    nom: any;
    email: any;
    telephone: any;
    dateDeNaissance: any;
    boitePostale: any;
    ville: any;
    pays: any;
    adresse: any;
    genre: any;
    entityName: any
    matricule: any
    numeroCarteProfessionnelle: any
    clientType: any
    rolesUsers: any;

    constructor(
        private platform: Platform,
        private _fb: FormBuilder,
        private route: ActivatedRoute,
        private _service: SuperAdminService,
        private router: Router,
        private responsive: ResponsiveService,
        private adminService: AdminService,
        private _loginService: LoginService,
        private location: Location
    ) {
        this.addForm = this._fb.group(
            {
                nom: ['', [Validators.required]],
                prenom: ['', [Validators.required]],
                email: ['', [
                    Validators.required,
                    Validators.email
                ]
                ],
                telephone: [''],
                dateDeNaissance: ['', [Validators.required]],
                boitePostale: [''],
                ville: ['', [Validators.required]],
                pays: ['', [Validators.required]],
                address: [''],
                genre: [''],
                clientType: [''],
                entityName: [null],
                matricule: [''],
                numeroCarteProfessionnelle: [''],
                role: ['']
            }
        )
    }


    async ngOnInit() {
        this.currentDeviceName = this.responsive.getDevice()
        this.rolesUsers = await this._loginService.getRoles();
        this.roleGestionnaire = (this.rolesUsers.includes('ROLE_GESTIONNAIRE')) ? true : false;
             if (this.route.snapshot.params['id']) {
            this.update = true
            if (this.addClient) {
                this.route.params.subscribe(
                    (p: Params) => {
                        this.id = +p['id']
                        this.adminService.getOne(this.id).subscribe(
                            data => {
                                this.data = data
                                console.log("this.data", this.data);
                                this.clientType = this.data['clientType'];
                                this.entityName = this.data['entityName']
                                this.typeClient = this.clientType
                                this.prenom = this.data['uuser'].prenom
                                this.nom = this.data['uuser'].nom
                                this.email = this.data['uuser'].email
                                this.telephone = this.data['uuser'].contact ? this.data['uuser'].contact.telephone : ''
                                this.dateDeNaissance = this.data['uuser'].dateDeNaissance
                                this.boitePostale = this.data['uuser'].contact ? this.data['uuser'].contact.boitePostale : ''
                                this.ville = this.data['uuser'].contact ? this.data['uuser'].contact.ville.nom : ''
                                this.pays = this.data['uuser'].contact ? this.data['uuser'].contact.ville.pays.nom : ''
                                this.adresse = this.data['uuser'].contact ? this.data['uuser'].contact.address : ''
                                this.genre = this.data['uuser'].genre
                            }
                        )
                    }
                )
            } else {
                this.route.params.subscribe(
                    (p: Params) => {
                        this.id = +p['id']
                        this._service.getOne('/super_admins', +this.id).subscribe(
                            data => {
                                console.log(data);
                                this.data = data
                                console.log("this.data", this.data);
                                this.prenom = this.data['user'].prenom
                                this.nom = this.data['user'].nom
                                this.email = this.data['user'].email
                                this.telephone = this.data['user'].contact.telephone
                                this.dateDeNaissance = this.data['user'].dateDeNaissance
                                this.boitePostale = this.data['user'].contact.boitePostale
                                this.ville = this.data['user'].contact.ville.nom
                                this.pays = this.data['user'].contact.ville.pays.nom
                                this.adresse = this.data['user'].contact.address
                                this.genre = this.data['user'].genre
                            },
                            err => {
                                console.log(err);

                            }
                        )
                    }
                )
            }

        }
        this.isDesktop = this.platform.is('desktop');
        this._service.get('pays').subscribe(
            (data: any) => {
                this.countries = data
                //this.countries = this.countries
            }
        );
    }

    hide() {
        this.errorLogin = false;
    }


    fetchPayByVilleEntered() {
        let ville
        ville = this.addForm.value.ville
        if (ville == '') {
            this.paysAutoComplete = false
        }
        console.log("ville", ville);
        this._service.search('/villes', ville).subscribe(
            data => {
                console.log("pays", data[0]['pays'].nom);
                this.paysAutoComplete = true
                this.pays = data[0]['pays'].nom
            },
            err => {
                console.log("eerr ===>", err);
            }
        )
    }

    fetchVilleByPaysEntered(e: { nom: any; }) {
        if (!this.addForm.value.pays) {
            this.isSelect = false
        }

        this._service.search('/pays', e.nom).subscribe(
            data => {
                this.villes = data[0].villes
                this.isSelect = true
            },
            err => {
                console.log("error");

            }
        )
    }

    typeChoices() {
        this.typeClient = this.addForm.value.clientType;
    }

    close() {
        if (this.addClient) {
            this.addClient && !this.roleGestionnaire ? this.router.navigate(["/admin/list-clients"]) : this.router.navigate(['/gestionnaire/list-clients'])
            return
        }
        if (this.addEmploye) {
            this.router.navigate(['/admin'])
            return
        } else {
            this.router.navigate(['/super-admins'])
            return
        }
        // this.addClient && !this.roleGestionnaire?this.router.navigate(["/admin/list-clients"]):this.addEmploye?this.router.navigate(['/admin']):this.router.navigate(['/super-admins'])
        // return
    }

    onSubmit() {
        if (this.positionClient) {
            if (this.id && this.addForm && this.addForm.value) {
                const superAmin = {
                    nom: this.addForm.value.nom,
                    prenom: this.addForm.value.prenom,
                    email: this.addForm.value.email,
                }

                if (this.addClient) {
                    let client = {
                        clientType: parseInt(this.addForm.value.clientType),
                        entityName: this.addForm.value.entityName,
                        user: {
                            nom: this.addForm.value.nom,
                            prenom: this.addForm.value.prenom,
                            email: this.addForm.value.email,
                            genre: parseInt(this.addForm.value.genre),
                            contact: {
                                telephone: this.addForm.value.telephone,
                                boitePostale: this.addForm.value.boitePostale,
                                ville: this.addForm.value.ville,
                                address: this.addForm.value.address
                            }
                        }
                    }
                    this.adminService.update(this.id, client).subscribe(
                        res => {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: 'edited success',
                                showConfirmButton: false,
                                timer: 1000
                            })
                            this.roleGestionnaire ? this.router.navigate(['/gestionnaire/list-clients']) : this.router.navigate(["/super-admins/list-clients"]);

                        },
                        err => {
                            console.log("err", err);

                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Something went wrong!'
                            })
                        }
                    )
                } else {
                    this._service.update('/super_admins', this.id, superAmin).subscribe(
                        res => {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: 'edited success',
                                showConfirmButton: false,
                                timer: 1000
                            })
                            this.router.navigate(["/admin"]);
                        },
                        err => {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Something went wrong!'
                            })
                        }
                    )
                }

            } else {
                let user = {
                    nom: this.addForm.value.nom,
                    prenom: this.addForm.value.prenom,
                    email: this.addForm.value.email,
                    dateDeNaissance: this.addForm.value.dateDeNaissance,
                    password: "password",
                    genre: parseInt(this.addForm.value.genre),
                    contact: {
                        telephone: this.addForm.value.telephone,
                        boitePostale: this.addForm.value.boitePostale,
                        ville: this.addForm.value.ville,
                        address: this.addForm.value.address
                    }
                }
                if (this.addClient) {
                    let client = {
                        clientType: parseInt(this.addForm.value.clientType),
                        entityName: this.addForm.value.entityName,
                        user: user
                    }
                    this.adminService.post(client).subscribe(
                        data => {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: 'Client succesfully added',
                                showConfirmButton: false,
                                timer: 1000
                            })
                            this.roleGestionnaire ? this.router.navigate(['/gestionnaire/list-clients']) : this.router.navigate(["/super-admins/list-clients"])
                            return
                        },
                        err => {
                            console.log(err);
                        }
                    )

                } else {
                    this._service.add('/super_admins', user).subscribe(
                        res => {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: 'Super Admin succesfully added',
                                showConfirmButton: false,
                                timer: 1000
                            })
                            this.router.navigate(["/admin"]);
                        },
                        err => {
                            console.log(err);

                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Something went wrong!'
                            })

                        }
                    )
                }
            }

        }else if (this.addForm.valid && this.addForm && this.addForm.value && this.addEmploye){

            const contact:any = {
                telephone: this.addForm.value.telephone,
                boitePostale: this.addForm.value.boitePostale,
                ville: this.addForm.value.ville,
                address: this.addForm.value.address
            }
            const data = {
                prenom: this.addForm.value.prenom,
                nom: this.addForm.value.nom,
                genre: this.addForm.value.genre,
                email:this.addForm.value.email,
                telephone: this.addForm.value.telephone,
                matricule: this.addForm.value.matricule,
                numeroCarteProfessionnelle: this.addForm.value.numeroCarteProfessionnelle,
                dateNaiss: this.addForm.value.dateDeNaissance,
                nomPays:this.addForm.value.pays,
                nomVille: this.addForm.value.ville,
                role: this.addForm.value.role,
                pays: this.addForm.value.pays,
                ville: this.addForm.value.ville,
                boitePostale: this.addForm.value.boitePostale,
                contact
            };
            const url = `/employes/add-employe`
            this._service.postData(data, url)
                .subscribe(result => {
                        if (result) {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: 'Employe ajouté avec success',
                                showConfirmButton: false,
                                timer: 1000
                            })
                            //     this._observableService.reloadUser.emit(true);
                            this.location.back();
                            // this.router.navigate('..');
                        }
                    },
                    (error: any) => {
                        console.log(this.addForm.value);
                        console.log(error);
                    })

        }

    }

}
