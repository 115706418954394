import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseUrl } from '../auth/login.service';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SiteService {

  constructor(private http:HttpClient) { }

  AddSite(site:any,idClient:number){
    return this.http.post(baseUrl+`/sites/${idClient}/addSites`,site);
  }

  postData(data:any, url: string){
    return this.http.post(baseUrl+ url, data);
  }

  getData(url: string): Observable<any>{
    return this.http.get(baseUrl+ url);
  }

  getAllSites(){
    return this.http.get(baseUrl+'/sites');
  }

  getAllPlannings(){
    return this.http.get(baseUrl+'/plannings');
  }

  getSiteById(id:number){
    return this.http.get(baseUrl+`/sites/${id}`);
  }
  getSitePlanning(idClient:number, idSite: number){
    return this.http.get(baseUrl+`/clients/${idClient}/sites/${idSite}/plannings`);
  }

  changeStatePlanning(id:number,state:any){
    let body={"etat":state};
    console.log(typeof(id));
    return this.http.put(baseUrl+`/plannings/${id}`,body);
  }

  fetchAffectations(id:number){
    return this.http.get(baseUrl+`/planning/${id}/affectations`);
  }

  enableAgent(id:number,etat:boolean){
    let body={"state":etat};
    return this.http.put(baseUrl+`/affectations/${id}`,body);
  }

  updatePlanning(id:number,planning:any){
    return this.http.put(baseUrl+`/plannings/${id}`,planning);
  }

  deletePlanning(id:number){
    return this.http.delete(baseUrl+`/plannings/${id}`);
  }

  getSitesByClient(email:string){
    return this.http.get(baseUrl+`/sitesByClients/${email}`);
  }

  postConsigne(consigne:any){
    return this.http.post(baseUrl+`/consignes`,consigne);
  }

  deleteConsigne(id:number){
    return this.http.delete(baseUrl+`/consignes/${id}`);
  }

  getOneConsigne(id:number){
    return this.http.get(baseUrl+`/consignes/${id}`);
  }

  putConsigne(id:number,consigne:any){
    return this.http.put(baseUrl+`/consignes/${id}`,consigne);
  }

  getAllConsignesClient(){
    return this.http.get(`${baseUrl}/consignesClient`);
  }

  sendDeleteAgentNotification(form:any){
    return this.http.post(`${baseUrl}/notif/planning/deleteAgent`,form);
  }

}
