import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { SiteService } from '../../../../services-lib/src/lib/site/site.service';
import Swal from 'sweetalert2';
import { LoginService } from '../../../../services-lib/src/lib/auth/login.service';
import { ResponsiveService } from '../../../../services-lib/src/lib/responsive/responsive.service';

@Component({
  selector: 'dev-list-consigne-common',
  templateUrl: './list-consigne-common.component.html',
  styleUrls: ['./list-consigne-common.component.css']
})
export class ListConsigneCommonComponent implements OnInit {

  @Input() rows:any;
  @Input() allRows:any;
  @Input() individuel:boolean=false;

  searchForm: FormGroup;
  option:string="email";

  currentDeviceName:string = '';
  rolesUsers:string[]=[];
  roleAdmin:boolean=false;
  roleGestionnaire:boolean=false;
  roleClient:boolean=false;

  constructor( private responsive:ResponsiveService,
               private _loginService: LoginService,
               private router:Router,
               private _fb: FormBuilder,
               private siteService:SiteService)
  {
    this.searchForm = this._fb.group({
      mail: ['']
    });
  }

  async ngOnInit() {
    this.currentDeviceName = this.responsive.getDevice();
    this.rolesUsers = await this._loginService.getRoles();
    this.roleAdmin = (this.rolesUsers.includes('ROLE_ADMIN'))?true:false;
    this.roleGestionnaire = (this.rolesUsers.includes('ROLE_GESTIONNAIRE'))?true:false;
    this.roleClient = (this.rolesUsers.includes('ROLE_CLIENT'))?true:false;
  }

  onAddConsigne(){
    if (this.roleGestionnaire) {
      this.router.navigate([`gestionnaire/addConsigne`]);
    } else if (this.roleAdmin) {
      this.router.navigate([`admin/addConsigne`]);
    }else if (this.roleClient) {
      this.router.navigate([`client/addConsigne`]);
    }
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    if (filterValue !== "") {
        this.rows = this.allRows.filter((row:any)=>{
            return row.createdBy.email.indexOf(filterValue) > -1;
        });
    }else{
          this.rows = this.allRows;
    }
  }

    EditConsigne(id:number){
        if (this.roleGestionnaire) {
            this.router.navigate([`gestionnaire/consigne/${id}/edit`]);
        } else if (this.roleAdmin) {
          this.router.navigate([`admin/consigne/${id}/edit`]);
        } else if (this.roleClient) {
          this.router.navigate([`client/consigne/${id}/edit`]);
        }
    }

    DeleteConsigne(id:number){
      Swal.fire({
        title: 'Are you sure?',
        text: "La suppression est irreversible !",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.siteService.deleteConsigne(id).subscribe(
            (data)=>{
              Swal.fire(
                'Deleted!',
                'La consigne a été supprimée avec success.',
                'success'
              )
              this.goBack();
            },
            (error)=>{
              console.log(error);
              Swal.fire(
                'Error!',
                'La consigne n\'a été supprimée !!!.',
                'error'
              )
            }
          );
        }
      })
    }

    goBack(){
      if(this.roleAdmin){
        this.router.navigate([`admin/consignes`]);
      }else if(this.roleGestionnaire){
        this.router.navigate([`gestionnaire/consignes`]);
      }else if (this.roleClient) {
        this.router.navigate([`client/consignes`]);
      }
        
      }

}
