import {formatDate} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {LoginService} from '../../../../services-lib/src/lib/auth/login.service';
import Swal from 'sweetalert2';
import {EntrepriseService} from '../../../../services-lib/src/lib/entreprise/entreprise.service';


class Auteur {
    id: number = 0;
    nom: string = '';
    prenom: string = '';
    email: string = '';
    dateDeNaisance = null;
}

class TypeConge {
    id: number = 0;
    titre: string = '';
    description: string = '';
}


class DemandeConge {
    id: number = 0;
    commentaire: string = '';
    auteur: Auteur | any;
    dateDebutSouhaitee: string = '';
    dateFinSouhaitee: string = '';
    etat: number = 0;
    typeConge: TypeConge | any;
    etatResultat: number = 0;
    justifcatif: Blob = new Blob();
}

@Component({
    selector: 'dev-details-demand-common-conge',
    templateUrl: './details-demand-common-conge.component.html',
    styleUrls: ['./details-demand-common-conge.component.css']
})
export class DetailsDemandCommonCongeComponent implements OnInit {

    id: number = 0;
    demandeConge: DemandeConge | any;
    justificatif: any;


    rolesUsers: string[] = [];
    roleAdmin: boolean = false;
    roleSuperAdmin: boolean = false;
    roleGestionnaire: boolean = false;
    roleAgent!: boolean;
    roleClient!: boolean;

    formValid: boolean = false;

    validDebut = false;
    validFin = false;
    validType: boolean = false;

    isDisabled: boolean = true;

    items = [
        {id: 0, title: 'ANNULER'},
        {id: 1, title: 'ACCORDER'},
        {id: 2, title: 'REPORTER'},
        {id: 3, title: 'REFUSER'},
    ];

    demandeCongeForm: FormGroup = new FormGroup({});
    resultatControl = new FormControl(this.items[2].title, [Validators.required]);
    dateFinAccordee: any;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private _fb: FormBuilder,
                private entrepriseService: EntrepriseService,
                private sanitizer: DomSanitizer,
                private _loginService: LoginService
    ) {
    }

    async ngOnInit() {
        this.getDetailsConge();
        this.demandeCongeForm = this._fb.group({
            typeId: [''],
            dateDebutAccordee: ['', []],
            dateFinAccordee: ['', []],
        });

        this.rolesUsers = await this._loginService.getRoles();
        this.roleAdmin = (this.rolesUsers.includes('ROLE_ADMIN'));
        this.roleGestionnaire = (this.rolesUsers.includes('ROLE_GESTIONNAIRE'));
        this.roleSuperAdmin = (this.rolesUsers.includes('ROLE_SUPERADMIN'));
        this.roleAgent = (this.rolesUsers.includes('ROLE_AGENT'));
        this.roleClient = (this.rolesUsers.includes('ROLE_CLIENT'));
        if (this.roleAgent && (this.roleAdmin || this.roleGestionnaire)) {
            this.roleAgent = false;
        }
    }

    ifFormValid() {
        if (this.validDebut && this.validFin && this.validType) {
            this.formValid = true;
        }
    }

    selectOneResult(result: any) {
        if (result.title !== "") {
            this.validType = true;
            console.log(result, this.resultatControl.value);
            if (result.id === 1) {
                this.isDisabled = false;
                this.ifFormValid();
            } else {
                this.isDisabled = true;
                this.formValid = true;
            }
        }
    }

    addEvent(type: string, event: any) {
        if (type === 'debut') {
            this.validDebut = event.value !== null;
        } else if (type === 'fin') {
            this.validFin = event.value !== null;
        }
        this.ifFormValid();
        console.log(event.value, type);
    }

    getDetailsConge() {
        this.id = this.route.snapshot.params['id'];
        this.entrepriseService.getOneDemandeConge(this.id).subscribe(
            (data: any) => {
                this.demandeConge = data;
                this.justificatif = this.demandeConge.justifcatif;
                console.log(data);
            });
    }


    display_programme(programme: any) {
        this.justificatif = this._base64ToArrayBuffer(programme);
    }

    _base64ToArrayBuffer(base64: any) {
        const binary_string = window.atob(base64);
        const len = binary_string.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }

    transform(programme: any) {

        let pro = this.sanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64,' + programme);
        console.log(pro);
        // this.selectedFileBLOB=pro;
        // const pdfDocGenerator = pdfMake.createPdf(pro);

        var objbuilder = '';
        objbuilder += ('<object width="100%" height="100%" data="data:application/pdf;base64,');
        objbuilder += (programme);
        objbuilder += ('" type="application/pdf" class="internal">');
        objbuilder += ('<embed src="data:application/pdf;base64,');
        objbuilder += (programme);
        objbuilder += ('" type="application/pdf"  />');
        objbuilder += ('</object>');

        var win = window.open("#", "_blank");
        var title = "my tab title";
        win?.document.write('<html><title>' + title + '</title><body style="margin-top: 0px; margin-left: 0px; margin-right: 0px; margin-bottom: 0px;">');
        win?.document.write(objbuilder);
        win?.document.write('</body></html>');
    }

    submitForm() {
        if (this.demandeCongeForm.valid) {
            if (this.resultatControl.value === 1) {
                this.demandeCongeForm.value.dateDebutAccordee = formatDate(this.demandeCongeForm.value.dateDebutAccordee, 'yyyy-MM-dd', 'en-US');
                this.demandeCongeForm.value.dateFinAccordee = formatDate(this.demandeCongeForm.value.dateFinAccordee, 'yyyy-MM-dd', 'en-US');
            }
            this.demandeCongeForm.value.etat = 1;
            this.demandeCongeForm.value.resultat = this.resultatControl.value;
            console.log('prepa', this.demandeCongeForm.value);
            this.entrepriseService.traiterDemandeConge(this.demandeCongeForm.value, this.demandeConge.id).subscribe(
                (data: any) => {
                    console.log('data', data);
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Demande congé traitée avec success',
                        showConfirmButton: false,
                        timer: 1000
                    });
                    this.demandeCongeForm.reset();
                    this.getDetailsConge();
                },
                (error: any) => {
                    console.log('error', error);
                }
            );
        }
    }

    goBack() {
        if (this.roleAdmin) {
            this.router.navigate(['/admin/demand-conge']);
        } else if (this.roleGestionnaire) {
            this.router.navigate(['/gestionnaire/demand-conge']);
        } else if (this.roleAgent) {
            this.router.navigate(['/agent/demand-conge']);
        }

    }
}