import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { LoginService } from '../../../services-lib/src/lib/auth/login.service';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators'
import { AuthentificationService } from '../authentification/authentification.service';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  tokenizedReq:any;
  constructor(
    private storage:Storage,
    private loginService:LoginService,
    private authService:AuthentificationService,
    ) { }

    intercept(request:HttpRequest <any>,next:HttpHandler){
      // return from (this.storage.get('token'))
      return from (this.loginService.getStoredToken())
          .pipe(
              switchMap(token=>{
                if((request.url.indexOf('/api/password/request') > -1) || (request.url.indexOf('/api/password/reset') > -1)  || (request.url.indexOf('/api/login') > -1)){
                  // console.log("intercepted");
                  return next.handle(request);
                }
                    request = request.clone({
                    setHeaders : {
                      Authorization: `Bearer ${token}`,
                      Accept: 'application/json',
                    }
                  });
                // console.log("Interceptor: "+token);
                return next.handle(request);
              })
          )
      }
}
