import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginService } from '../auth/login.service';
import { baseUrl } from '../auth/login.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private http: HttpClient, private loginService: LoginService) { }
  httpOptions = {
    headers: new HttpHeaders({
      'Authorization': 'Bearer ' +this.loginService.getStoredToken(),
    })
  };

  get(){
    return this.http.get(`${baseUrl}/clients?order[uuser.nom]=asc`);
  }
  getClientByEmail(email:string){
    return this.http.post(`${baseUrl}/client/email`, {email:email});
  }
  checkEmployeByEmail(email:string){
    return this.http.post(`${baseUrl}/employe/email`, {email:email});
  }
  getOne(id:number){
    return this.http.get(`${baseUrl}/clients/${id}`);
  }
  searchByMail(params:any){
    return this.http.get<any>(`${baseUrl}/clients?uuser.email=${params}`)
  }
  post(data:any): Observable<any>{
    return this.http.post<any>(`${baseUrl}/clients`, data);
  }

  update(id: number, data: any): Observable<any>{
    return this.http.put<any>(`${baseUrl}/clients/${id}`, data)
  }
  delete(id:number) : Observable<any> {
    return this.http.delete<any>(`${baseUrl}/clients/${id}`)
  }
  getGroupes(){
    return this.http.get(`${baseUrl}/groupes`)
  }
  addGroupe(data:any):Observable<any>{
    return this.http.post<any>(`${baseUrl}/groups`, data);
  }
  getOneGroupe(id:number){
    return this.http.get(`${baseUrl}/groupes/${id}`);
  }
  updateGroupe(id: number, data: any): Observable<any>{
    return this.http.put<any>(`${baseUrl}/groupes/${id}`, data)
  }
  deleteGroupe(id:number){
    return this.http.delete(`${baseUrl}/groupes/${id}`)
  }
  addUserInGroupe(id:number, data:any){
    return this.http.put<any>(`${baseUrl}/groupes/${id}/users`, data)
  }
  deleteUserInGroupe(id:number, idUser:number){
    return this.http.put<any>(`${baseUrl}/groupes/${id}/users/${idUser}`, null)
  }

  //planning stuff
  getUserPlannings(id:number){
    return this.http.get(`${baseUrl}/agents/${id}/plannings`);
  }
  getConnectedPlanning(){
    return this.http.get(`${baseUrl}/connected/plannings`);
  }
  getAgentPlanning() {
    return this.http.get(`${baseUrl}/client/plannings`);
  }
}
