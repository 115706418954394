import { Injectable, Type } from '@angular/core';
import {Platform} from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import {MediaObserver} from "@angular/flex-layout";


@Injectable({
  providedIn: 'root'
})
export class ResponsiveService {

  private windowsWidth = new BehaviorSubject(this.getWidth());
  private diviceType = new BehaviorSubject(this.getDevice());
  currentWidth = this.windowsWidth.asObservable();
  currentDevice = this.diviceType.asObservable();


  constructor(private platform:Platform) {
    this.changeWith(this.getWidth());
    this.changeDevice(this.getDevice());
  }

  onMediaChange(mediaService: MediaObserver): any {
    if (mediaService.isActive('lt-md')) {
      return true;
    }
    if (mediaService.isActive('gt-sm') || mediaService.isActive('md')) {
      return false;
    }
  }


  changeWith(width: number) {
    this.windowsWidth.next(width);
  }

  getWidth(){
    return this.platform.width();
  }

  changeDevice(type: any) {
    this.windowsWidth.next(type);
  }

  getDevice(){
    let deviceType:string="";
    if (this.platform.is('mobile') || this.platform.is('mobileweb') || this.getWidth()<=475) {
        deviceType="mobile";
    }else if(this.platform.is('tablet')){
        deviceType="tablet";
    }else if(this.platform.is('desktop')){
        deviceType="desktop";
    }
    return deviceType;
  }



}
