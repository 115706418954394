import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ChangePasswordComponent } from './requestMail/change-password/change-password.component';
import { GetCodeVerificationComponent } from './requestMail/get-code-verification/get-code-verification.component';
import { SendCodeVerificationComponent } from './requestMail/send-code-verification/send-code-verification.component';
import { HeaderEmployeComponent } from "../../../Admin/src/app/employes/header-employe/header-employe.component";

const routes: Routes = [

  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'accueil',
    pathMatch: 'full'
  },
  { path: 'getCodeVerification',
     component: GetCodeVerificationComponent
    },
    { path: 'changePassword',
    component: ChangePasswordComponent
   },
    { path: 'sendCodeVerification',
     component: SendCodeVerificationComponent
    },
  {
    path:'admin',
    loadChildren: () => import('../../../Admin/src/app/home/home.module').then(m=>m.HomePageModule)
  },
  {
    path:'super-admins',
    loadChildren: () => import('../../../superAdmin/src/app/home/home.module').then( m => m.HomeAdminPageModule)
  },
  {
    path:'gestionnaire',
    loadChildren: () => import('../../../Gesionnaire/src/app/home/home.module').then(m => m.HomeGestionnairePageModule)
  },
  {
    path: 'agent',
    loadChildren: () => import('../../../Agent/src/app/home/home.module').then(m=>m.HomePageModule)
  },
  {
    path: 'client',
    loadChildren: () => import('../../../Client/src/app/home/home.module').then(m=>m.HomePageModule)
  },
  {
    path: 'gestionnaire/statistiques',
    loadChildren: () => import('../../../Gesionnaire/src/app/statistiques/statistiques.module').then(m=>m.StatistiquesPageModule)
  },
  {
    path: 'gestionnaire/notifications',
    loadChildren: () => import('../../../Gesionnaire/src/app/notifications/notifications.module').then(m=>m.NotificationsPageModule)
  },
  {
    path: 'admin/home',
    loadChildren: () => import('../../../Admin/src/app/home/home.module').then(m=>m.HomePageModule)
  },
  {
    path: 'admin/list-employe',
    loadChildren: () => import('../../../Admin/src/app/list-employes/list-employes.module').then(m=>m.ListEmployesPageModule)
  },
  {
    path: 'admin/statistiques',
    loadChildren: () => import('../../../Admin/src/app/statistiques/statistiques.module').then(m=>m.StatistiquesPageModule)
  },
  {
    path: 'client/statistiques',
    loadChildren: () => import('../../../Client/src/app/statistiques/statistiques.module').then(m=>m.StatistiquesPageModule)
  },
  {
    path: 'client/plannings',
    loadChildren: () => import('../../../Client/src/app/planning-client/planning-client.module').then(m=>m.PlanningClientPageModule)
  },
  {
    path: 'client/finances',
    loadChildren: () => import('../../../Client/src/app/client-finance/client-finance.module').then(m=>m.ClientFinancePageModule)
  },
  {
    path: 'client/sites',
    loadChildren: () => import('../../../Client/src/app/client-site/client-site.module').then(m=>m.ClientSitePageModule)
  },
  {
    path: 'agent/statistiques',
    loadChildren: () => import('../../../Agent/src/app/statistiques/statistiques.module').then(m=>m.StatistiquesPageModule)
  },
  {
    path: 'accueil',
    loadChildren: () => import('./accueil/accueil.module').then( m => m.AccueilPageModule)
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules,  onSameUrlNavigation: 'reload',})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
