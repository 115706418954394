import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'lib-menu-button-item',
  templateUrl: './menu-button-item.component.html',
  styleUrls: ['./menu-button-item.component.scss'],
})
export class MenuButtonItemComponent implements OnInit {
  @Input()urlPath: any;
  @Input()iconMenu: any;
  @Input()title: any;

  constructor() { }

  ngOnInit() {}

}
