import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {EmployesService} from '../../../../services-lib/src/lib/employes/employes.service';
import {FullObservableService} from '../../../../services-lib/src/lib/fullObservable/fullObservable.service';
import {ResponsiveService} from '../../../../services-lib/src/lib/responsive/responsive.service';
import Swal from 'sweetalert2';
import {AuthentificationService} from '../../authentification/authentification.service';
import {SiteService} from '../../../../services-lib/src/lib/site/site.service';
import {ErrorStateMatcher} from '@angular/material/core';
import {Router} from '@angular/router';
import {Location} from "@angular/common";
import {SuperAdminService} from './../../../../services-lib/src/lib/superAdmin/SuperAdmin.service';


/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}


@Component({
    selector: 'dev-addsite',
    templateUrl: './addsite.component.html',
    styleUrls: ['./addsite.component.css']
})
export class AddsiteComponent implements OnInit {

    @Input() rows: any

    public errorLogin: boolean = false;
    formGroup: any = FormGroup;
    currentDeviceName: string = "";
    public countries: any;
    @Input()  public roleGestionnaire: any;
    @Input()  public roleAdmin: any;
    isSelect: any;
    ville: any;

    villes: any[] = [];
    pays: any[] = [];
    paysAutoComplete = false
    public keyword = 'nom';


    constructor(
        private employeService: EmployesService,
        private authService: AuthentificationService,
        private responsive: ResponsiveService,
        private _observableService: FullObservableService,
        private siteService: SiteService,
        private router: Router,
        private _service: SuperAdminService,
        private location: Location) {
    }

    ngOnInit() {
        this.currentDeviceName = this.responsive.getDevice();
        this.initForm();
        this._service.get('pays').subscribe(
            (data: any) => {
                this.countries = data
            }
        )
    }

    initForm() {
        this.formGroup = new FormGroup({
            nomSite: new FormControl('', [Validators.required]),
            adresseSite: new FormControl('', [Validators.required]),
            boitePostaleSite: new FormControl('', [Validators.required]),
            telephoneSite: new FormControl('', [Validators.required]),
            pays: new FormControl('', [Validators.required]),
            ville: new FormControl('', [Validators.required]),
            idClient: new FormControl('', [Validators.required]),
            description: new FormControl('', []),
        })
    }

    submitForm() {
        if (this.formGroup.valid && this.formGroup && this.formGroup.value){
           const contact:any = {
               telephone: this.formGroup.value.telephoneSite,
               boitePostale: this.formGroup.value.boitePostaleSite,
               ville: this.formGroup.value.ville,
               address: this.formGroup.value.address
           }
            const data = {
                nomSite: this.formGroup.value.nomSite,
                adresseSite: this.formGroup.value.adresseSite,
                telephoneSite: this.formGroup.value.telephoneSite,
                boitePostaleSite: this.formGroup.value.boitePostaleSite,
                pays: this.formGroup.value.pays,
                ville: this.formGroup.value.ville,
                idClient: this.formGroup.value.idClient,
                description: this.formGroup.value.description,
                contact
            }
            const url = `/sites/${this.formGroup.value.idClient}/add-client-sites`
            this.siteService.postData(data, url)
                .subscribe(result => {
                        if (result) {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: 'Site ajouté avec success',
                                showConfirmButton: false,
                                timer: 1000
                            })
                       //     this._observableService.reloadUser.emit(true);
                            this.initForm();
                            this.location.back()
                            // this.router.navigate('..');
                        }
                    },
                    error => {
                        console.log(this.formGroup.value);
                        console.log(error);
                    })

        }
    }

    hide() {
        this.errorLogin = false;
    }

    selected = new FormControl('valid', [
        Validators.required,
        Validators.pattern('valid'),
    ]);

    selectFormControl = new FormControl('valid', [
        Validators.required,
        Validators.pattern('valid'),
    ]);

    nativeSelectFormControl = new FormControl('valid', [
        Validators.required,
        Validators.pattern('valid'),
    ]);

    matcher = new MyErrorStateMatcher();


   fetchPayByVilleEntered() {
        let ville
        ville = this.formGroup.value.ville
        if (ville === '') {
            this.paysAutoComplete = false
        }
        console.log("ville", ville);
        this._service.search('/villes', ville).subscribe(
            data => {
                console.log("pays", data[0]['pays'].nom);
                this.paysAutoComplete = true
                this.pays = data[0]['pays'].nom
            },
            err => {
                console.log("eerr ===>", err);
            }
        )
    }

    fetchVilleByPaysEntered(e: { nom: any; }) {
        console.log(e);

        if (!this.formGroup.value.pays) {
            this.isSelect = false
        }

        this._service.search('/pays', e.nom).subscribe(
            (data: any[]) => {
                 if (data && data.length > 0 && data[0]) {
                     this.villes = data[0].villes
                 }
                this.isSelect = true;
            },
            err => {
                console.log("error");

            }
        )
    }

    close(): any {
        this.roleGestionnaire ? this.router.navigate(["/gestionnaire/list-sites"]) : this.router.navigate(['/admin/list-sites'])
    }
}
