import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {SubscriptionLike} from 'rxjs';
import { environment } from '../../../environments/environment';
import Swal from 'sweetalert2';
import {ResponsiveService} from '../../../../../services-lib/src/lib/responsive/responsive.service';

@Component({
  selector: 'login-get-code-verification',
  templateUrl: './get-code-verification.component.html',
  styleUrls: ['./get-code-verification.component.scss'],
})
export class GetCodeVerificationComponent implements OnInit {
  baseUrl = environment.apiURL;

  loginForm: FormGroup;
  forgetEmail: boolean = false;
  currentDeviceName: string;
  subscription: SubscriptionLike;


  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private responsive: ResponsiveService) {
    this.loginForm = formBuilder.group(
      {
        email: ['', [Validators.required]]
      }
    );
  }

  ngOnInit() {
    this.currentDeviceName = this.responsive.getDevice();
    this.responsive.changeDevice(this.responsive.getDevice());
    this.subscription = this.responsive.currentDevice
      .subscribe(
        message => {
          this.currentDeviceName = message;
          console.log('new width' + message);
        })
  }


  async onSubmit() {
    return this.http.post(`${this.baseUrl}password/request`, this.loginForm.value)
      .subscribe(
        result => {
          // console.log(result);
          Swal.fire(
            'Email verifiée!',
            'Un code de confirmation vous a été envoyé.',
            'success'
          )
          // Swal.fire({
          //   position: 'top-end',
          //   icon: 'success',
          //   title: 'edited success',
          //   showConfirmButton: false,
          //   timer: 1000
          // })
          this.router.navigateByUrl('sendCodeVerification');
        },
        error => {
          this.forgetEmail = true;
          console.log(error)
        }
      )
  }

  hide() {
    this.forgetEmail = false;
  }


}
