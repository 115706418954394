import {Component, OnInit} from '@angular/core';
import Swal from 'sweetalert2'
import {ActivatedRoute, Router} from "@angular/router";
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';

@Component({
  selector: 'login-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  myScriptElement: HTMLScriptElement | undefined;
  dropdownVisible: boolean = false;
  user: any;
  authority: any[] = [];
  superadmin: boolean = false
  admin: boolean = false
  dirigeant: boolean = false
  superviseur: boolean = false
  encadreur: boolean = false
  manager: boolean = false
  employe: boolean = false
  prof: boolean = false
  parent: boolean = false
  etudiant: boolean = false
  page!: string;

  constructor(private route: ActivatedRoute,
              private router: Router) {
    this.route.url.subscribe(url => {
      this.page = url[0].path;
    })
    /*
        authService.getUserProfile().subscribe( data => {
          this.user = data
          this.authority = data.authorities
          for (const item of this.authority) {
            if(item.code == "SUPERADMIN"){
              this.superadmin =true
            }
            if(item.code == "ADMIN"){
              this.admin =true
            }
            if(item.code == "DIRIGEANT"){
              this.dirigeant =true
            }
            if(item.code == "SUPERVISEUR"){
              this.superviseur =true
            }
            if(item.code == "ENCADREUR"){
              this.encadreur
            }
            if(item.code == "MANAGER"){
              this.manager =true
            }
            if(item.code == "EMPLOYE"){
              this.employe =true
            }
            if(item.code == "PROF"){
              this.prof =true
            }
            if(item.code == "PARENT"){
              this.parent =true
            }
            if(item.code == "ETUDIANT"){
              this.etudiant =true
            }
          }
        })
    */

  }

  ngOnInit(): void {

  }

  toggle() {
    this.dropdownVisible = !this.dropdownVisible;
  }

  logout() {
    localStorage.removeItem('access_token');
    this.router.navigate(['login'])
  }

/*
  logout() {
    Swal.fire({
      title: 'Etes-vous sur de vouloir vous déconnecter ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui !',
      cancelButtonText: 'Non'
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    })
  }
*/
  loaderService: any;

}
