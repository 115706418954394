import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FullObservableService {
//Déclaration des évènements
public reloadUser!: EventEmitter<any>;
public reloadPlannings!: EventEmitter<any>;
public reloadConsignes!: EventEmitter<any>;
public reloadNotifs!: EventEmitter<any>;
  public reloadLogin!: EventEmitter<any>;

constructor() {
  this.reloadUser = new EventEmitter<any>();
  this.reloadPlannings = new EventEmitter<any>();
  this.reloadConsignes = new EventEmitter<any>();
  this.reloadNotifs = new EventEmitter<any>();
  this.reloadLogin = new EventEmitter<any>();

}

}
