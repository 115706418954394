import {Component, OnInit} from '@angular/core';
import {ResponsiveService} from '../../../../services-lib/src/lib/responsive/responsive.service';

@Component({
    selector: 'lib-messages',
    templateUrl: './messages.component.html',
    styleUrls: ['./messages.component.css']
})
export class MessagesComponent implements OnInit {
    currentDeviceName!: string;
    messages : any[] = [];
    selected: any;
    detailsBox!: boolean;

    constructor(
        private responsive: ResponsiveService,
    ) {
    }

    ngOnInit(): void {
        this.currentDeviceName = this.responsive.getDevice();
    }

    details(planning: any, i: any) {

        if (this.selected === i) {
            setTimeout(
                () => {
                    this.detailsBox = false;
                    this.selected = undefined;

                },
                500
            );
        } else {
            setTimeout(() => {
                this.detailsBox = true;
                this.selected = i;

            }, 500)
        }
    }

}
