import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {Storage} from '@ionic/storage';
import {SubscriptionLike} from 'rxjs';
import { environment } from '../../../environments/environment';
import Swal from 'sweetalert2';
import {LoginService} from '../../../../../services-lib/src/lib/auth/login.service';
import {ResponsiveService} from '../../../../../services-lib/src/lib/responsive/responsive.service';

@Component({
  selector: 'login-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  baseUrl = environment.apiURL;

  loginForm: FormGroup;
  forgetEmail: boolean = false;
  code: string = "";
  currentDeviceName: string;
  subscription: SubscriptionLike;
  getMessageError: any;
  message: any;


  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private storage: Storage,
    private loginService: LoginService,
    private responsive: ResponsiveService
  ) {

    this.loginForm = this.formBuilder.group(
      {
        password: ['', [Validators.required]],
        password_confirm: ['', [Validators.required]]
      }
    );
  }

  ngOnInit() {
    this.currentDeviceName = this.responsive.getDevice();
    this.responsive.changeDevice(this.responsive.getDevice());
    this.subscription = this.responsive.currentDevice
      .subscribe(
        message => {
          this.currentDeviceName = message;
          console.log('new width' + message);
        })
  }

  async onSubmit() {
    this.code = await this.loginService.getCodeVerification();
    return this.http.put(`${this.baseUrl}password/reset/${this.code}`, this.loginForm.value)
      .subscribe(
        result => {
          Swal.fire(
            'Success!',
            'Votre mot de passe a été modifié avec succes.',
            'success'
          )
          this.router.navigateByUrl('home');
        },
        error => {
          this.forgetEmail = true;
        }
      )
  }

  hide() {
    this.forgetEmail = false;
  }


}
