import {Component, Input, OnInit} from '@angular/core';
import {FullObservableService} from '../../../../services-lib/src/lib/fullObservable/fullObservable.service';
import {NotifsService} from '../../../../services-lib/src/lib/notifications/notifs.service';


class Notif {
    etat: number = 0;
    id: number = 0;
    modifyAt: string = "";
    important: boolean = false;
    notification = {
        id: 0,
        titre: "",
        message: "",
        type: "PAS DE TYPE",
        createdAt: "",
    }
}


@Component({
    selector: 'dev-list-notifs-common',
    templateUrl: './list-notifs-common.component.html',
    styleUrls: ['./list-notifs-common.component.css']
})


export class ListNotifsCommonComponent implements OnInit {
    @Input() notifs: any;
    notifications: any[] = [];
    allNotifs: any[] = [];
    notifToDisplay: Notif = new Notif();
    activateFirstNotif: boolean = false;

    listHideActive: boolean = false;
    listImportantActive: boolean = false;
    listNotSeenActive: boolean = false;
    listSeenActive: boolean = false;

    constructor(
        private notifService: NotifsService,
        private _fullObservable: FullObservableService) {
    }

    ngOnInit(): void {
        this.getNotifs();
        this._fullObservable.reloadNotifs.subscribe(
            (data) => {
                this.getNotifs();
            }
        );
    }

    getNotifs() {
        this.notifService.getNotifications().subscribe(
            (data: any) => {
                // let newNotifs = data.filter((notif:any) => notif.etat == 1);
                this.allNotifs = data.sort((a: any, b: any) => {
                    return new Date(b.notification.createdAt).getTime() - new Date(a.notification.createdAt).getTime()
                });
                this.notifications = this.allNotifs.filter((notif: any) => notif.etat !== 3);
                let Notifs = this.notifications.filter((notif: any) => notif.etat == 2);
                if (Notifs.length > 0 && !this.activateFirstNotif) {
                    this.activateFirstNotif = true;
                    this.notifToDisplay = Notifs[0];
                }
                if (this.listHideActive) {
                    this.getNotifHided();
                } else if (this.listImportantActive) {
                    this.getNotifImportant();
                } else if (this.listSeenActive) {
                    this.getNotifsAlreadyRead();
                } else {
                    this.allListeInactives();
                }
                console.log(data);
            },
            err => console.log(err)
        )
    }

    allListeInactives() {
        this.listSeenActive = false;
        this.listHideActive = false;
        this.listImportantActive = false;
    }

    getNotifNotRead() {
        this.listSeenActive = false;
        this.listHideActive = false;
        this.listImportantActive = false;
        this.notifications = this.allNotifs.filter((notif: any) => notif.etat === 1);
    }

    getNotifsAlreadyRead() {
        this.listSeenActive = true;
        this.listNotSeenActive = false;
        this.listHideActive = false;
        this.listImportantActive = false;
        this.notifications = this.allNotifs.filter((notif: any) => notif.etat === 2);
    }

    getNotifImportant() {
        this.listNotSeenActive = false;
        this.listSeenActive = false;
        this.listImportantActive = true;
        this.listHideActive = false;
        console.log('trurrrr');
        this.notifications = this.allNotifs.filter((notif: any) => notif.important === true);
    }

    getNotifHided() {
        this.listNotSeenActive = false;
        this.listSeenActive = false;
        this.listHideActive = true;
        this.listImportantActive = false;
        this.notifications = this.allNotifs.filter((notif: any) => notif.etat === 3);
    }


    displayNotif(notif: any) {
        if (notif.etat !== 3) {
            this.notifService.updateNotif(notif.id, {etat: 2}).subscribe(
                (data: any) => {
                    this.getNotifs();
                    this._fullObservable.reloadNotifs.emit(true);
                },
            )
        }
        this.notifToDisplay = notif;
    }


    hideNotif(notif: any) {
        let value = notif.etat === 3 ? 2 : 3;
        this.notifService.updateNotif(notif.id, {etat: value}).subscribe(
            (data: any) => {
                this.getNotifs();
                this._fullObservable.reloadNotifs.emit(true);
                this.notifToDisplay = data;
            },
        );
    }

    markImportant(notifToDisplay: any) {
        let value = !notifToDisplay.important;
        this.notifService.updateNotif(notifToDisplay.id, {important: value}).subscribe(
            (data: any) => {
                this.getNotifs();
                this._fullObservable.reloadNotifs.emit(true);
                this.notifToDisplay = data;
            },
        );
    }

}
