import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ServicesLibComponent } from './services-lib.component';



@NgModule({
  declarations: [
    ServicesLibComponent
  ],
  imports: [
    HttpClientModule
  ],
  exports: [
    ServicesLibComponent
  ]
})
export class ServicesLibModule { }
