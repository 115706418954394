import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LoginService} from '../auth/login.service';
import {Observable} from 'rxjs';
import {baseUrl} from '../auth/login.service';

@Injectable({
    providedIn: 'root'
})
export class SuperAdminService {

    constructor(private http: HttpClient, private loginService: LoginService) {
    }

    httpOptions = {
        headers: new HttpHeaders({
            'Authorization': 'Bearer ' + this.loginService.getStoredToken(),
        })
    };

    postData(data:any, url: string){
        return this.http.post(baseUrl+ url, data);
    }
    get(params: any) {
        return this.http.get(`${baseUrl}/${params}`);
    }

    async post(params: any, data: any) {
        return await this.http.post(`${params}`, data)
    }

    getOne(suffix: any, id: number) {
        return this.http.get(`${baseUrl}${suffix}/${id}`, this.httpOptions);
    }

    add(suffix: any, data: any): Observable<any> {
        return this.http.post<any>(`${baseUrl}${suffix}`, data, this.httpOptions);
    }

    delete(suffix: any, id: number) {
        return this.http.delete(`${baseUrl}${suffix}/${id}`, this.httpOptions);
    }

    update(suffix: any, id: number, data: any): Observable<any> {
        return this.http.put<any>(`${baseUrl}${suffix}/${id}`, data, this.httpOptions)
    }

    search(suffix: any, params: any) {
        return this.http.get<any>(`${baseUrl}${suffix}?nom=${params}`, this.httpOptions)
    }

    searchByMail(suffix: any, params: any) {
        return this.http.get<any>(`${baseUrl}${suffix}?user.email=${params}`, this.httpOptions)
    }

    hardDelete(id: number) {
        return this.http.delete(`${baseUrl}/super_admin/${id}/delete`, this.httpOptions);
    }

    deleteVilleInPays(idPays: number, idVille: number) {
        return this.http.delete(`${baseUrl}/configuration/pays/${idPays}/ville/${idVille}`)
    }

    addVilleInPays(id: number, body: any) {
        return this.http.put(`${baseUrl}/configuration/pays/${id}/villes`, body);
    }

}
