import { C, COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
// import { Observable } from '../../../../login/node_modules/rxjs';
import { GroupeService } from '../../../..//services-lib/src/lib/groupe/groupe.service';
import { ResponsiveService } from '../../../..//services-lib/src/lib/responsive/responsive.service';
import { SiteService } from '../../../../services-lib/src/lib/site/site.service';
import { map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import {DatePipe, Location} from '@angular/common';
import { FullObservableService } from '../../../../services-lib/src/lib/fullObservable/fullObservable.service';
import { Observable } from 'rxjs';
import {global} from "@angular/compiler/src/util";
import * as moment from 'moment';
import {ThemePalette} from "@angular/material/core";
import {NgxMatDatetimePicker} from "@angular-material-components/datetime-picker";

@Component({
  selector: 'dev-add-common-planning',
  templateUrl: './add-common-planning.component.html',
  styleUrls: ['./add-common-planning.component.css']
})

export class AddCommonPlanningComponent implements OnInit {

  //Pagination part
  config = {
    id: 'custom8',
    itemsPerPage: 5,
    currentPage: 1,
    totalItems: 0
   };
   
 

   nomSite:string="";
   modeEdit:boolean=false;
   planningEdit:any;
   
  formulaire:boolean= false;
  public errorLogin:boolean=false;
  currentDeviceName: string='';
  formGroup:any = FormGroup;
  editFormGroup:any= FormGroup;
  panelOpenState = false;

  //Chipts
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  
  groupeCtrl = new FormControl();
  filteredGroupes: Observable<string[]> = new Observable<string[]>();
  groupes:string[]=[];
  groupeAgents=[];
  idGroupes:number[]=[];
  allGroupes:string[]=[];
  agents:any[]=[];
  affectations:any[]=[];
  affectationsEdit:any[]=[];

  plannings:any[]=[];

  data:any;

  @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement> =  {} as ElementRef;


  @ViewChild(NgxMatDatetimePicker, {static: true}) picker?: NgxMatDatetimePicker<any>;
  @ViewChild(NgxMatDatetimePicker, {static: true}) endPicker?: NgxMatDatetimePicker<any>;


  public date: moment.Moment | any;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment | any;
  public maxDate: moment.Moment | any;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  constructor(
    private responsive:ResponsiveService,
    private groupesService: GroupeService,
    private route:ActivatedRoute,
    private siteService: SiteService,
    private _planngObservable:FullObservableService,
    private location: Location
  ) { 
    this.initForm();
    this.filteredGroupes = this.groupeCtrl.valueChanges.pipe(
      startWith(null),
      map((groupe: string | null) => groupe ? this._filter(groupe) : this.allGroupes.slice()));
  }

  ngOnInit() {
    this.currentDeviceName = this.responsive.getDevice();
    this.getGroupes();
    this.getAllPlannings();
    this.getSiteInformation(this.route.snapshot.params.id);
    this._planngObservable.reloadPlannings.emit(true);
  }

  activateEdit(planning:any){
    this.formulaire = true;
    this.planningEdit = planning;
    this.modeEdit = !this.modeEdit;
    this.editFormGroup =  new FormGroup ({
      titreEdit: new FormControl(`${planning.titre}`,[Validators.required]),
      descriptionEdit: new FormControl(`${planning.description}`,[Validators.required]),
      dateDebutEdit: new FormControl(`${planning.dateDebut}`,[Validators.required]),
      dateFinEdit: new FormControl(`${planning.dateFin}`,[Validators.required]),
  });
    this.fetchAffectation(planning);
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }
  

  initForm(){
    this.formGroup =  new FormGroup ({
        titre: new FormControl('',[Validators.required]),
        description: new FormControl('',[Validators.required]),
        dateDebut: new FormControl('',[Validators.required]),
        dateFin: new FormControl('',[Validators.required]),
        idAgents: new FormControl('',[])
    });
  }

  deletePLanning(planning:any){
    Swal.fire({
      title: 'Etes vous surs?',
      text: "La suppression est irreversible !",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui, Supprimer!'
    }).then((resultAlert) => {
      this.siteService.deletePlanning(planning.id)
      .subscribe(result=>{
        if (resultAlert.isConfirmed && result) {
          Swal.fire(
            'Supprimé!',
            'Le planning a été supprimé !.',
            'success'
          )
        }
        this.plannings=[];
        this.getAllPlannings();
      }
      ,error=>{
        console.log(error);
      });
    })
  }

  submitForm(){
    let idAgents = this.agents.map((item:any)=> item.id);
    if(this.formGroup.valid)
    {
      this.formGroup.value.agents=JSON.stringify(idAgents);
      let id = this.route.snapshot.params['id'];
      console.log(this.formGroup.value);
      this.groupesService.postOnePlanning(this.formGroup.value,id)
          .subscribe(result=>{
              this.plannings=[];
              this.getAllPlannings();

              if(result){
                Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'Planning ajouté avec success',
                  showConfirmButton: false,
                  timer: 1000
                })
                // this._observableService.reloadUser.emit(true);
                this.initForm();
                this.location.back();
              }
          },
          error=>{
              console.log(error);
          })

     }
  }

  updateForm(id:number){
    let planning ={
      "titre":this.editFormGroup.value.titreEdit,
      "description":this.editFormGroup.value.descriptionEdit,
      "dateDebut":this.editFormGroup.value.dateDebutEdit,
      "dateFin":this.editFormGroup.value.dateFinEdit
    }
    console.log(planning);

    this.siteService.updatePlanning(id,planning).subscribe(
      (data:any) => {
        if(data){
          this.plannings=[];
          this.getAllPlannings();
          this.fetchAffectation(data);

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Planning modifié avec success',
            showConfirmButton: false,
            timer: 1000
          })
        }
       

      },
      (error) => {
        console.log(error);
      });

  }

  fetchAffectation(planning: any){
    this.panelOpenState = true;
    this.siteService.fetchAffectations(planning.id).subscribe(
      (data:any) => {
        if (this.modeEdit) {
          this.affectationsEdit = data;
        }else{
          this.affectations[planning.id] = data;
        }
        console.log(this.affectations);

       
      },
      (error) => {
        console.log(error);
      });
  }

  getSiteInformation(id:number){
    this.siteService.getSiteById(id).subscribe(
      (data:any) => {
        this.nomSite = data.nom;
      },
      (error) => {
        console.log(error);
      });
  }

  enableAgent(affectation:any){
    let etat =  !affectation.state;
    this.siteService.enableAgent(affectation.id,etat).subscribe(
      (data:any) => {
        this.fetchAffectation(affectation.planning);
        //Partie d'envoi de notification
        let form = {
          "emailAgent":affectation.agent.email,
          "state":etat,
          "planning":data.planning.titre
        };
        this.siteService.sendDeleteAgentNotification(form).subscribe(
          (data:any) => {
              console.log(data);
          }
        );
      },
      (error) => {
        console.log(error);
      });
  }

  recupererAgents(){
    let tab ={
      "idAgents":JSON.stringify(this.idGroupes)
    };
    if (this.idGroupes.length>0) {
      this.groupesService.getUniqueAgents(tab).subscribe(
        (data:any) => {
          this.agents=[...data];  
        },
        (error) => {
          console.log(error);
        });
    }else{
      this.agents=[];
    }
  }

  onDeleteAgent(agent:any){
    this.agents = this.agents.filter((agent_exist:any)=>{
      return agent.id !== agent_exist.id;
    });
  }

  changeState(planning:any){
    console.log('plannins ===>', planning)
    let etat =  planning.etat===1? 2:1;
    this.siteService.changeStatePlanning(planning.id,etat).subscribe(
      (data:any) => {
        this.plannings=[];
        this.getAllPlannings();
      },
      (error) => {
        console.log(error);
      });

  }


  getGroupes(){
    this.groupesService.getAllGroupes().subscribe(
      (data:any) => {
        data.forEach((element:any) => {
          this.allGroupes.push(element.nom);
          this.groupeAgents.push(element as never);
          this.data =data;
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getAllPlannings(){
    this.siteService.getAllPlannings().subscribe(
      (data:any) => {
        let pipe = new DatePipe('en-US');
        data.forEach((element:any) => { 
          // let dd = pipe.transform(new Date(element.dateDebut), 'yyyy-MM-dd');
          // let jj = pipe.transform(new Date(), 'yyyy-MM-dd');
          let yearPlanning = pipe.transform(new Date(element.dateDebut), 'yyyy');
          let yearNow = pipe.transform(new Date(), 'yyyy');
          let monthPlanning = pipe.transform(new Date(element.dateDebut), 'MM');
          let monthNow = pipe.transform(new Date(), 'MM');
          if (yearPlanning === yearNow && monthPlanning === monthNow) {
            this.plannings.push(element);
          }
  
        });
        this.config.totalItems = this.plannings.length;
      },
      (error) => {
        console.log(error);
      }
    );  
  }

  showForm(){
    this.formulaire = !this.formulaire;
    if (!this.formulaire) {
      this.modeEdit = false;
    }
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    let exist:boolean=false;
    // Add our groupe;
    if (value) {
      for (let i = 0; i < this.groupes.length; i++) {
        if (this.groupes[i] === value){
          exist=true;
        } 
      }
      exist = !(this.allGroupes.indexOf(value) !== -1);
     
      if (!exist) {
        this.groupes.push(value);
        let groupe:any = this.groupeAgents.filter((groupe:any)=>{
          return groupe.nom === value;
        });
        this.idGroupes.push(groupe[0].id);
        console.log(this.idGroupes);       
      }
    }
    // Clear the input value
    event.chipInput!.clear();
    this.groupeCtrl.setValue(null);
  }


  remove(nomGroupe: string): void {
    const index:any = this.groupes.indexOf(nomGroupe as never);
    if (index >= 0) {
      this.groupes.splice(index, 1);

      let groupe:any = this.groupeAgents.filter((groupe:any)=>{
        return groupe.nom === nomGroupe;
      });
      this.idGroupes.splice(this.idGroupes.indexOf(groupe[0].id),1);
      console.log(this.idGroupes);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    let value = event.option.value;
    let exist:boolean=false;

    for (let i = 0; i < this.groupes.length; i++) {
      if (this.groupes[i] === value) {
        exist=true;
      }
    }

    if (!exist) {
      this.groupes.push(event.option.viewValue as never);
      this.fruitInput.nativeElement.value = '';

      this.groupeCtrl.setValue(null);

      let groupe:any = this.groupeAgents.filter((groupe:any)=>{
        return groupe.nom === value;
      });

      this.idGroupes.push(groupe[0].id);
      console.log(this.idGroupes);

    }else{
      this.groupeCtrl.setValue(null);
      console.log("existe deja");
    }
   
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allGroupes.filter((groupe:string) => groupe.toLowerCase().includes(filterValue));
  }


}
