import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AdminService} from '../../../../services-lib/src/lib/admin/admin.service';
import {LoginService} from '../../../../services-lib/src/lib/auth/login.service';
import {EntrepriseService} from '../../../../services-lib/src/lib/entreprise/entreprise.service';
import {ResponsiveService} from '../../../../services-lib/src/lib/responsive/responsive.service';
import {SiteService} from '../../../../services-lib/src/lib/site/site.service';
import {Observable} from 'rxjs/internal/Observable';
import {map, startWith} from 'rxjs/operators';
import Swal from 'sweetalert2';
import {FullObservableService} from '../../../../services-lib/src/lib/fullObservable/fullObservable.service';

@Component({
    selector: 'dev-edit-consigne-common',
    templateUrl: './edit-consigne-common.component.html',
    styleUrls: ['./edit-consigne-common.component.css']
})
export class EditConsigneCommonComponent implements OnInit {

    @Input() rows: any;
    @Input() allRows: any;
    @Input() individuel: boolean = true;

    clients: any[] = [];
    clientChoosed: any;
    sites: any[] = [];
    siteChoosed: any;
    types: any[] = [];
    typeChoosed: any;

    commentaire: string = "";


    validEmail: boolean = false;
    validSite = false;
    validType = false;
    validForm = false;


    consigneForm: FormGroup;

    activeConfirm: boolean = false;
    activeForm: boolean = false;

    email = new FormControl();
    emailClients: string[] = [];
    filteredEmails: Observable<string[]> = new Observable<string[]>();

    type = new FormControl();
    typesConsigne: string[] = [];
    filteredTypes: Observable<string[]> = new Observable<string[]>();

    site = new FormControl();
    sitesClients: string[] = [];
    filteredSites: Observable<string[]> = new Observable<string[]>();


    option: string = "email";

    currentDeviceName: string = '';
    rolesUsers: string[] = [];
    roleAdmin: boolean = false;
    roleGestionnaire: boolean = false;
    validFormE: any;

    constructor(private responsive: ResponsiveService,
                private _loginService: LoginService,
                private router: Router,
                private _fb: FormBuilder,
                private adminService: AdminService,
                private siteService: SiteService,
                private entrepriseService: EntrepriseService,
                private route: ActivatedRoute,
                private fullObservable: FullObservableService) {
        this.consigneForm = this._fb.group({
            typeId: [''],
            commentaire: [''],
            siteId: ['']
        });
    }

    async ngOnInit() {
        this.currentDeviceName = this.responsive.getDevice();
        this.rolesUsers = await this._loginService.getRoles();
        this.roleAdmin = (this.rolesUsers.includes('ROLE_ADMIN')) ? true : false;
        this.roleGestionnaire = (this.rolesUsers.includes('ROLE_GESTIONNAIRE')) ? true : false;

        this.getClients();
        this.getTypesConsignes();

        this.filteredEmails = this.email.valueChanges.pipe(
            startWith(''),
            map(value => this._filter_Emails(value)),
        );
        this.filteredSites = this.site.valueChanges.pipe(
            startWith(''),
            map(value => this._filter_Sites(value)),
        );
        this.filteredTypes = this.type.valueChanges.pipe(
            startWith(''),
            map(value => this._filter_Types(value)),
        );

        this.getConsigne();
    }


    getConsigne() {
        let id = this.route.snapshot.params['id'];
        this.siteService.getOneConsigne(id).subscribe(
            (data: any) => {
                this.email.setValue(data.site.client.uuser.email);
                let emailClient = data.site.client.uuser.email;
                if (emailClient) {
                    this.site.setValue(data.site.nom);
                    this.siteChoosed = data.site;
                    this.validSite = true;
                }
                this.type.setValue(data.type.nom);
                this.typeChoosed = data.type;
                this.commentaire = data.commentaire;
            },
            (error) => {
                console.log(error);
            }
        );
    }


    private _filter_Emails(value: string): string[] {
        const filterValue = value.toLowerCase();
        this.emailChosen(filterValue);
        return this.emailClients.filter(option => option.toLowerCase().includes(filterValue));
    }

    private _filter_Sites(value: string): string[] {
        const filterValue = value.toLowerCase();
        this.siteChoosen(filterValue);
        return this.sitesClients.filter(option => option.toLowerCase().includes(filterValue));
    }

    private _filter_Types(value: string): string[] {
        const filterValue = value.toLowerCase();
        this.typeChoosen(filterValue);
        return this.typesConsigne.filter(option => option.toLowerCase().includes(filterValue));
    }

    submitForm() {
        let id = this.route.snapshot.params['id'];
        if (this.validEmail && this.validSite && this.validType) {
            this.validForm = true;
        }
        if (this.clientChoosed && this.siteChoosed && this.typeChoosed && this.validForm) {

            this.consigneForm.value.clientId = this.clientChoosed.id;
            this.consigneForm.value.siteId = this.siteChoosed.id;
            this.consigneForm.value.typeId = this.typeChoosed.id;

            if (this.consigneForm.valid) {
                this.siteService.putConsigne(id, this.consigneForm.value).subscribe(
                    (data) => {
                        this.fullObservable.reloadConsignes.emit(true);
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: 'Consigne editée avec success',
                            showConfirmButton: false,
                            timer: 1000
                        });
                    },
                    (error) => {
                        console.log('error', error);
                    }
                );
            }
        } else {
            console.log('formulaire invalide', this.siteChoosed);
        }
    }

    getClients() {
        this.adminService.get().subscribe(
            (data: any) => {
                data.forEach((element: any) => {
                    this.clients.push(element.uuser);
                    this.emailClients.push(element.uuser.email);
                });
            },
            (err) => {
                console.log(err);
            }
        );
    }

    getSitesClients(email: string) {
        this.siteService.getSitesByClient(email).subscribe(
            (data: any) => {
                data.forEach((element: any) => {
                    this.sites.push(element);
                    this.sitesClients.push(element.nom);
                });
            },
            (err) => {
                console.log(err);
            }
        );
    }

    getTypesConsignes() {
        this.entrepriseService.getTypesConsignes().subscribe(
            (data: any) => {
                this.types = data;
                this.typesConsigne = data.map((type: any) => type.nom);
            }
        );
    }


    verifyConfirm() {
        this.activeForm = true;
    }


    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        if (filterValue !== "") {
            this.rows = this.allRows.filter((row: any) => {
                return row.createdBy.email.indexOf(filterValue) > -1;
            });
        } else {
            this.rows = this.allRows;
        }
    }

    checkDetails(id: number) {
        if (this.roleGestionnaire) {
            this.router.navigate([`gestionnaire/site/${id}/details`]);
        } else if (this.roleAdmin) {
            this.router.navigate([`admin/site/${id}/details`]);
        }
    }

    emailChosen(email: string) {
        this.validEmail = false;
        this.clients.forEach((element: any) => {
            if (element.email === email) {
                this.clientChoosed = element;
                this.getSitesClients(email);
                this.validEmail = true;
            }
        });
        if (!this.validEmail) {
            this.validSite = false;
            this.sites = [];
            this.sitesClients = [];
        }
    }

    siteChoosen(site: string) {
        this.validSite = false;
        this.sites.forEach((element: any) => {
            if (element.nom === site) {
                this.siteChoosed = element;
                this.validSite = true;
            }
        });
    }

    typeChoosen(type: string) {
        this.validType = false;
        this.types.forEach((element: any) => {
            if (element.nom === type) {
                this.typeChoosed = element;
                this.validType = true;
            }
        });
    }

    cleanForm() {
        this.consigneForm.reset();
        this.validForm = false;
        this.clientChoosed = null;
        this.siteChoosed = null;
        this.typeChoosed = null;
        this.validEmail = false;
        this.validSite = false;
        this.validType = false;
        this.email.setValue('');
        this.site.setValue('');
        this.type.setValue('');
        this.consigneForm.value.commentaire = "";
    }

    goBack() {
        if (this.roleGestionnaire) {
            this.router.navigate([`gestionnaire/consignes`]);
        } else if (this.roleAdmin) {
            this.router.navigate([`admin/consignes`]);
        }
    }


}
