import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {AdminService} from '../../../../services-lib/src/lib/admin/admin.service';
import {LoginService} from '../../../../services-lib/src/lib/auth/login.service';
import {EntrepriseService} from '../../../../services-lib/src/lib/entreprise/entreprise.service';
import {ResponsiveService} from '../../../../services-lib/src/lib/responsive/responsive.service';
import {SiteService} from '../../../../services-lib/src/lib/site/site.service';
import {Observable} from 'rxjs/internal/Observable';
import {map, startWith} from 'rxjs/operators';
import Swal from 'sweetalert2';
import {UserService} from '../../../../services-lib/src/lib/user/user.service';
import {FullObservableService} from '../../../../services-lib/src/lib/fullObservable/fullObservable.service';


@Component({
    selector: 'dev-add-consigne-common-client',
    templateUrl: './add-consigne-common-client.component.html',
    styleUrls: ['./add-consigne-common-client.component.css']
})
export class AddConsigneCommonClientComponent implements OnInit {


    @Input() rows: any;
    @Input() allRows: any;
    @Input() individuel: boolean = true;

    sites: any[] = [];
    siteChoosed: any;
    types: any[] = [];
    typeChoosed: any;

    emailClient: string = "";


    validSite = false;
    validType = false;
    validForm = false;


    consigneForm: FormGroup;

    activeConfirm: boolean = false;
    activeForm: boolean = false;

    type = new FormControl();
    typesConsigne: string[] = [];
    filteredTypes: Observable<string[]> = new Observable<string[]>();

    site = new FormControl();
    sitesClients: string[] = [];
    filteredSites: Observable<string[]> = new Observable<string[]>();


    option: string = "email";

    currentDeviceName: string = '';
    rolesUsers: string[] = [];
    roleAdmin: boolean = false;
    roleGestionnaire: boolean = false;
    roleClient: boolean = false;
    validFormE: any;

    constructor(private responsive: ResponsiveService,
                private _loginService: LoginService,
                private router: Router,
                private _fb: FormBuilder,
                private adminService: AdminService,
                private siteService: SiteService,
                private entrepriseService: EntrepriseService,
                private userService: UserService,
                private fullObservable: FullObservableService) {
        this.consigneForm = this._fb.group({
            typeId: [''],
            commentaire: [''],
            siteId: ['']
        });
    }

    async ngOnInit() {
        this.currentDeviceName = this.responsive.getDevice();
        this.rolesUsers = await this._loginService.getRoles();
        this.roleClient = (this.rolesUsers.includes('ROLE_CLIENT')) ? true : false;

        this.getEmailClient();
        this.getTypesConsignes();

        this.filteredSites = this.site.valueChanges.pipe(
            startWith(''),
            map(value => this._filter_Sites(value)),
        );
        this.filteredTypes = this.type.valueChanges.pipe(
            startWith(''),
            map(value => this._filter_Types(value)),
        );
    }

    getEmailClient() {
        this.userService.getUserProfil().subscribe(
            (data: any) => {
                this.emailClient = data.email;
                this.getSites();
                console.log('email', this.emailClient)
            },
            (err) => {
                console.log(err);
            }
        );
    }

    private _filter_Sites(value: string): string[] {
        const filterValue = value.toLowerCase();
        this.siteChoosen(filterValue);
        return this.sitesClients.filter(option => option.toLowerCase().includes(filterValue));
    }

    private _filter_Types(value: string): string[] {
        const filterValue = value.toLowerCase();
        this.typeChoosen(filterValue);
        return this.typesConsigne.filter(option => option.toLowerCase().includes(filterValue));
    }

    submitForm() {
        if (this.validSite && this.validType) {
            this.validForm = true;
        }
        if (this.siteChoosed && this.typeChoosed && this.validForm) {
            console.log('submitForm');
            this.consigneForm.value.siteId = this.siteChoosed.id;
            this.consigneForm.value.typeId = this.typeChoosed.id;
            if (this.consigneForm.valid) {
                this.siteService.postConsigne(this.consigneForm.value).subscribe(
                    (data) => {
                        this.cleanForm();
                        this.fullObservable.reloadConsignes.emit(true);
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: 'Consigne ajoutée avec success',
                            showConfirmButton: false,
                            timer: 1000
                        })
                        this.goBack();
                    },
                    (error) => {
                        console.log('error', error);
                    }
                );
            } else {
                console.log('formulaire invalide', this.consigneForm.value);
            }
        }

        console.log(this.consigneForm.value);
    }


    getSites() {
        this.siteService.getSitesByClient(this.emailClient).subscribe(
            (data: any) => {
                data.forEach((element: any) => {
                    this.sites.push(element);
                    this.sitesClients.push(element.nom);
                });
            },
            (err) => {
                console.log(err);
            }
        );
    }

    getTypesConsignes() {
        this.entrepriseService.getTypesConsignes().subscribe(
            (data: any) => {
                this.types = data;
                this.typesConsigne = data.map((type: any) => type.nom);
                console.log('types consignes', this.typesConsigne)
            }
        );
    }

    verifyConfirm() {
        this.activeForm = true;
    }

    siteChoosen(site: string) {
        this.validSite = false;
        this.sites.forEach((element: any) => {
            if (element.nom === site) {
                this.siteChoosed = element;
                this.validSite = true;
            }
        });
    }

    typeChoosen(type: string) {
        this.validType = false;
        this.types.forEach((element: any) => {
            if (element.nom === type) {
                this.typeChoosed = element;
                this.validType = true;
            }
        });
    }

    cleanForm() {
        this.consigneForm.reset();
        this.validForm = false;
        this.siteChoosed = null;
        this.typeChoosed = null;
        this.validSite = false;
        this.validType = false;
        this.site.setValue('');
        this.type.setValue('');
        this.consigneForm.value.commentaire = "";
    }

    goBack() {
        this.router.navigate([`client/consignes`]);
    }


}
