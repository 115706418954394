import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {EntrepriseService} from '../../../../services-lib/src/lib/entreprise/entreprise.service';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {formatDate} from '@angular/common';
import Swal from 'sweetalert2';
import {ActivatedRoute, Router} from '@angular/router';
import {LoginService} from '../../../../services-lib/src/lib/auth/login.service';

@Component({
    selector: 'dev-demand-common-conge',
    templateUrl: './demand-common-conge.component.html',
    styleUrls: ['./demand-common-conge.component.scss']
})
export class DemandCommonCongeComponent implements OnInit {


    @Input() editActive: boolean = false;
    @Input() congeEdit: any;

    types: any[] = [];

    typeChoosed: any;

    newDemandeActive: boolean = false;
    listDemandesActive: boolean = true;
    mesDemandesActive: boolean = false;

    rolesUsers: string[] = [];
    roleAdmin: boolean = false;
    roleSuperAdmin: boolean = false;
    roleGestionnaire: boolean = false;
    roleAgent!: boolean;
    roleClient!: boolean;

    option: string = "email";

    rows: any[] = [];
    allRows: any[] = [];

    validType = false;
    validDebut = false;
    validFin = false;
    validJusificatif: boolean = true;
    fileimported: boolean = false;

    justificatif: File = new File([""], "");

    searchForm: FormGroup = new FormGroup({});

    demandeCongeForm: FormGroup = new FormGroup({});
    site = new FormControl();
    type = new FormControl();
    typesConge: string[] = [];
    filteredTypes: Observable<string[]> = new Observable<string[]>();
    email = new FormControl();
    listGroupCompetencesDisplay: any;
    addCompetenceDisplay: any;
    validFormE: any;
    dateFinSouhaitee: any;

    constructor(
        private _fb: FormBuilder,
        private entrepriseService: EntrepriseService,
        private route: Router,
        private router: ActivatedRoute,
        private _loginService: LoginService
    ) {

        this.searchForm = this._fb.group({
            mail: ['']
        })


    }

    async ngOnInit() {
        this.demandeCongeForm = this._fb.group({
            typeId: [''],
            commentaire: [''],
            dateDebutSouhaitee: ['', [Validators.required]],
            dateFinSouhaitee: ['', [Validators.required]],
            justificatif: [''],
        });

        this.rolesUsers = await this._loginService.getRoles();
        this.roleAdmin = (this.rolesUsers && this.rolesUsers.includes('ROLE_ADMIN'));
        this.roleGestionnaire = (this.rolesUsers && this.rolesUsers.includes('ROLE_GESTIONNAIRE'));
        this.roleSuperAdmin = (this.rolesUsers && this.rolesUsers.includes('ROLE_SUPERADMIN'));
        this.roleAgent = (this.rolesUsers && this.rolesUsers.includes('ROLE_AGENT'));
        this.roleClient = (this.rolesUsers && this.rolesUsers.includes('ROLE_CLIENT'));
        if (this.roleAgent && (this.roleAdmin || this.roleGestionnaire)) {
            this.roleAgent = false;
        }
        if (this.roleAgent) {
            this.listDemandesActive = false;
            this.mesDemandesActive = true;
        }

        this.filteredTypes = this.type.valueChanges.pipe(
            startWith(''),
            map(value => this._filter_Types(value)),
        );

        this.getTypesConges();
        if (this.mesDemandesActive) {
            this.getmyDemandes();
        } else if (this.listDemandesActive) {
            this.getAllConges();
        }

        if (this.editActive) {
            console.log('edit conge', this.congeEdit);
            let id = this.router.snapshot.params['id'];
            if (id !== 0) {
                this.getDetailsConge(id);
            }
            this.getDetailsDemande(this.congeEdit);
        }
    }

    searchBy(value: string) {
        this.option = value;
        // this.roleFilter=value==="role"?true:false;
        console.log(value);
    }

    resultSelect(id: number, all: boolean = false) {
        this.rows = all ? this.allRows : this.allRows.filter((row: any) => {
            return row.etatResultat === id;
        });
    }

    etatSelect(etat: boolean, all: boolean) {
        this.rows = all ? this.allRows.filter((row: any) => {
            return row.etat === etat;
        }) : this.allRows;
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        console.log(this.option);
        if (filterValue !== "") {
            this.rows = this.allRows.filter((row: any) => {
                if (this.option === "nom") {
                    return row.auteur.nom.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
                } else if (this.option === "prenom") {
                    return row.auteur.prenom.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
                } else if (this.option === "etat") {
                    return row.matricule.indexOf(filterValue) > -1;
                } else if (this.option === "type") {
                    return row.typeConge.titre.indexOf(filterValue) > -1;
                } else if (this.option === "annee") {
                    return row.createdAt.substring(0, 4).search(filterValue) > -1;
                } else {
                    return row.auteur.email.indexOf(filterValue) > -1;
                }
            })
        } else {
            this.rows = this.allRows;
        }
    }

    getDetailsConge(id: number) {
        this.entrepriseService.getOneDemandeConge(id).subscribe(
            (data: any) => {
                this.congeEdit = data;
                this.justificatif = this.congeEdit.justifcatif;
                console.log(data);
            });
    }

    private _filter_Types(value: string): string[] {
        const filterValue = value.toLowerCase();
        this.typeChoosen(filterValue);
        return this.typesConge.filter(option => option.toLowerCase().includes(filterValue));
    }

    checkDetails(demande: any) {
        if (this.roleAdmin) {
            this.route.navigate(['admin/demand-conge/' + demande.id + '/details']);
        } else if (this.roleGestionnaire) {
            this.route.navigate(['gestionnaire/demand-conge/' + demande.id + '/details']);
        } else if (this.roleAgent) {
            this.route.navigate(['agent/demand-conge/' + demande.id + '/details']);
        }

    }

    getDetailsDemande(demande: any) {
        this.mesDemandesActive = false;
        this.listDemandesActive = false;
        this.newDemandeActive = true;
        console.log('demande', demande);
        this.demandeCongeForm.value.commentaire = demande.commentaire;
        this.demandeCongeForm.value.dateDebutSouhaitee = demande.dateDebutSouhaitee;
        this.demandeCongeForm.patchValue({
            typeId: demande.typeId,
            commentaire: demande.commentaire,
            dateDebutSouhaitee: demande.dateDebutSouhaitee,
            dateFinSouhaitee: demande.dateFinSouhaitee,
            justificatif: demande.justificatif,
        });
        // this.typeChoosen(demande.type.titre);
    }

    typeChoosen(type: string) {
        this.validType = false;
        this.types.forEach((element: any) => {
            if (element.titre === type) {
                this.typeChoosed = element;
                this.validType = true;
            }
        });
    }

    editDemandConge(conge: any) {
        if (this.roleAdmin) {
            this.route.navigate(['admin/demand-conge/' + conge.id + '/edit']);
        } else if (this.roleGestionnaire) {
            this.route.navigate(['gestionnaire/demand-conge/' + conge.id + '/edit']);
        } else if (this.roleAgent) {
            this.route.navigate(['agent/demand-conge/' + conge.id + '/edit']);
        }

    }

    getTypesConges() {
        this.entrepriseService.getTypesConges().subscribe(
            (data: any) => {
                this.types = data;
                this.typesConge = data.map((type: any) => type.titre);
                console.log('types consignes', this.typesConge)
            }
        );
    }

    getAllConges() {
        this.entrepriseService.getAllConges().subscribe(
            (data: any) => {
                this.rows = data;
                this.allRows = data;
                console.log('conges', this.rows);
            }
        );
    }

    getmyDemandes() {
        this.entrepriseService.getMyConges().subscribe(
            (data: any) => {
                this.rows = data.data;
                this.allRows = data.data;
                console.log('mes conges', this.rows);
            }
        );
    }

    handleFileInput(event: any) {
        this.justificatif = event.target.files?.item(0);
        if (this.justificatif.size > 0) {
            this.fileimported = true;
        }
        // if (this.justificatif.size > 1048576) {
        //   Swal.fire({
        //     position: 'top-end',
        //     icon: 'error',
        //     title: 'Le fichier est trop volumineux',
        //     showConfirmButton: false,
        //     timer: 1000
        //   });
        //   this.justificatif = new File([""], "");
        // }
    }

    goBack() {
        this.demandeCongeForm.reset();
        this.justificatif = new File([""], "");
        this.fileimported = false;
        this.type = new FormControl();
    }

    addEvent(type: string, event: any) {
        if (type === 'debut' && event) {
            this.validDebut = event.value !== null;
        } else if (type === 'fin') {
            this.validFin = event.value !== null;
        }
        console.log(event.value, type);
    }

    submitForm() {
        if (this.demandeCongeForm.valid && this.validType && this.validDebut && this.validFin) {
            this.demandeCongeForm.value.dateDebutSouhaitee = formatDate(this.demandeCongeForm.value.dateDebutSouhaitee, 'yyyy-MM-dd', 'en-US');
            this.demandeCongeForm.value.dateFinSouhaitee = formatDate(this.demandeCongeForm.value.dateFinSouhaitee, 'yyyy-MM-dd', 'en-US');
            console.log('form valide', this.demandeCongeForm.value);
            this.demandeCongeForm.value.typeId = this.typeChoosed.id;
            this.entrepriseService.addDemandeConge(this.demandeCongeForm.value, this.justificatif).subscribe(
                (data: any) => {
                    console.log('data', data);
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Demande congé ajouté avec success',
                        showConfirmButton: false,
                        timer: 1000
                    });
                    this.demandeCongeForm.reset();
                },
                (error: any) => {
                    console.log('error', error);
                }
            );
        }
    }

    activateNewDemande() {
        this.newDemandeActive = true;
        this.listDemandesActive = false;
        this.mesDemandesActive = false;
        console.log('new demande');
    }

    activateListDemandes() {
        this.newDemandeActive = false;
        this.listDemandesActive = true;
        this.mesDemandesActive = false;
        this.getAllConges();
    }

    activateMesDemandes() {
        this.newDemandeActive = false;
        this.listDemandesActive = false;
        this.mesDemandesActive = true;
        this.getmyDemandes();
    }

}
