import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {baseUrl, LoginService} from '../auth/login.service';
import {tap} from "rxjs/operators";
import { environment } from '../../../../../projects/login/src/environments/environment';
export const merculeHubUrl = environment.merculeHubUrl;
@Injectable({
  providedIn: 'root'
})
export class NotifsService {

  constructor(private http:HttpClient, private sseService: LoginService) { }

  getNotifications(){
    return this.http.get(baseUrl+'/getNotifs');
  }

  updateNotif(id:number,form:any){
    return this.http.put(`${baseUrl}/user_notifications/${id}`,form)
  }


  sync() {
    const url = new URL(environment.merculeHubUrl);
    url.searchParams.append('topic', `/{id}`);

    return this.sseService.getServerSentEvent(`${url}`).pipe(
        tap((event: { data: string; }) => {
          const data = JSON.parse(event.data);
          const stock = data.stock;
        //  this.notificationService.add();
         /* runStoreAction('wines', StoreActions.UpdateEntities, {
            payload: {
              data: { stock },
              entityIds: data.id,
            },
          });*/
        }),
    );
  }

}
