import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, SubscriptionLike } from 'rxjs';
import { baseUrl } from '../auth/login.service';




@Injectable({
  providedIn: 'root'
})
export class EmployesService {

  private employeObject = new BehaviorSubject(null);
  currentEmploye = this.employeObject.asObservable();

  chargerObjetEmploye(employe:any) {
    this.employeObject.next(employe);
  }


  constructor(private http:HttpClient) { }

  getAllEmployes(){
    return this.http.get(`${baseUrl}/employes`);
  }

  AddEmploye(form:any):Observable<any>{
    return this.http.post(`${baseUrl}/employes/register`,form);
  }

  EditEmploye(form:any,id:number):Observable<any>{
    return this.http.put(`${baseUrl}/employes/${id}`,form);
  }

  getOneEmploye(id:number){
    return this.http.get(`${baseUrl}/employes/${id}`);
  }

  searchByMail(params:string){
    return this.http.get<any>(`${baseUrl}/employes?user.email=${params}`)
  }
  deleteEmploye(id:number){
    return this.http.delete(`${baseUrl}/employes/${id}`);
  }

  updateEvenement(id:number, data:any){
    return this.http.put(`${baseUrl}/type_evenements/${id}`,data);
  }
  createEvenment(data:any){
    return this.http.post(`${baseUrl}/typeEvenement/create`,data);
  }

  updateTypeConsigne(id:number, data:any){
    return this.http.put(`${baseUrl}/type_consignes/${id}`,data);
  }
  creatTypeConsigne(data:any){
    return this.http.post(`${baseUrl}/type_consignes`,data);
  }

  creatTypeConge(data:any){
    return this.http.post(`${baseUrl}/type_conges`,data);
  }

  updateTypeConge(id:number, data:any){
    return this.http.put(`${baseUrl}/type_conges/${id}`,data);
  }

}
