import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { baseUrl } from '../auth/login.service';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http:HttpClient) { }

  getUserProfil(){
    return this.http.get(`${baseUrl}/users/profil`);
  }

  updateUser(id:number,user:any){
    return this.http.put(`${baseUrl}/users/${id}`,user);
  }

  getUserByEmail(email: any){
    // @ts-ignore
    return this.http.get(`${baseUrl}/users?email=${email}`);
  }

  getMainCourantePeriod(dateCrea: any) {
    return this.http.get(`${baseUrl}/plannings/main_courante/${dateCrea}`);
  }

  getPlanningOfClientPeriod(dateCrea: any) {
    return this.http.get(`${baseUrl}/plannings/client/${dateCrea}`);
  }

  getMainCouranteOfOneClient(dateCrea: any) {
    return this.http.get(`${baseUrl}/main_courante/client/${dateCrea}`);
  }

  getMainCouranteOfOneAgent(dateCrea: any) {
    return this.http.get(`${baseUrl}/main_courante/agent/${dateCrea}`);
  }

  getPlanningBySiteOfOneClient(dateCrea: any) {
    return this.http.get(`${baseUrl}/planning/client/${dateCrea}`);
  }

  getPlanningBySiteOfOneAgent(dateCrea: any) {
    return this.http.get(`${baseUrl}/planning/agent/${dateCrea}`);
  }

  getAbsenceOfOneAgent(dateCrea: any) {
    return this.http.get(`${baseUrl}/agent/demande/${dateCrea}`);
  }
}
