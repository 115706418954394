import {AfterViewInit, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {CalendarOptions} from '@fullcalendar/angular';
import {MenuItem, PrimeNGConfig} from 'primeng/api';
import {ResponsiveService} from '../../../../services-lib/src/lib/responsive/responsive.service';
import {AdminService} from '../../../../services-lib/src/lib/admin/admin.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SiteService} from '../../../../services-lib/src/lib/site/site.service';
import {EntrepriseService} from '../../../../services-lib/src/lib/entreprise/entreprise.service';
import {EmployesService} from '../../../../services-lib/src/lib/employes/employes.service';
import {LoginService} from '../../../../services-lib/src/lib/auth/login.service';
import {FullObservableService} from '../../../../services-lib/src/lib/fullObservable/fullObservable.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {concat, Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {GoogleMap} from '@angular/google-maps';

@Component({
    selector: 'lib-plannings',
    templateUrl: './plannings.component.html',
    styleUrls: ['./plannings.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class PlanningsComponent implements OnInit, AfterViewInit {
    @Input() userPlanning: boolean = false;
    @Input() sitePlanning: boolean = false;
    @Input() entreprise: boolean = false;
    @Input() agent: boolean = false;
    @Input() client: boolean = false;
    @Input() hidePlannings: boolean = false;
    @Input() addPlanning: boolean = false;
    @Input() userType: string = "";

    clients: any[] = [];
    sites: any[] = [];
    plannings: any[] = [];
    events: any[] = [];
    event: any[] = [];
    emailAdmin: any;
    currentDeviceName: any;
    activeItem = 0;
    bc: boolean = false;
    clickedEvent: any;
    show: boolean = false;
    items: MenuItem[] = [
        {
            label: 'Plannings',
            command: () => {
                this.activeItem = 0
            }
        },
        {
            label: 'Calendrier',
            command: () => {
                this.activeItem = 1
            }
        },
    ];
    detailsBox: boolean = false
    // selected: any = 0;
    // calendarOptions: any;
    selected: any;
    calendarOptions!: CalendarOptions;
    idEntreprise: any;
    entreprisePlanning: any;

    progress = false;
    public startDate = new Date();
    public currentMonth = 0;
    public currentYear = 1900;

    myControlClients = new FormControl();
    myControlSites = new FormControl();

    emailClients: string[] = [];
    siteClients: string[] = [];

    filteredEmailClients: Observable<string[]> = new Observable<string[]>();
    filteredSiteClients: Observable<string[]> = new Observable<string[]>();

    disabledSite: boolean = false;
    idSite: number = 0;
    action = '';
    viewMonth: any = '';
    public endDate: Date = new Date();

    options: any;
    position: any;
    marker: any[] = [];
    // @ts-ignore
    center: google.maps.LatLngLiteral | any;
    locale = false;

    constructor(
        private primengConfig: PrimeNGConfig,
        private _responsive: ResponsiveService,
        private _adminService: AdminService,
        private route: ActivatedRoute,
        private _siteService: SiteService,
        private _service: EntrepriseService,
        private loginService: LoginService,
        private employeService: EmployesService,
        private _planningObervable: FullObservableService,
        private siteService: SiteService,
        private router: Router
    ) {
    }

    ngOnInit() {
        this.startDate = new Date();
        this.endDate = new Date();

        const dateCal = new Date();
        this.viewMonth = this.startDate.getMonth();
        this.currentMonth = dateCal.getMonth();
        this.currentYear = dateCal.getFullYear();
        this.filteredEmailClients = this.myControlClients.valueChanges.pipe(
            startWith(''),
            map(value => this._filterEmailClients(value))
        );
        this.filteredSiteClients = this.myControlSites.valueChanges.pipe(
            startWith(''),
            map(value => this._filterSiteClients(value)),
        );


        (!this.agent) ? this.fetchAllClients() : '';

        this.currentDeviceName = this._responsive.getDevice()
        this.primengConfig.ripple = true;
        this.progress = true;

        if (this.agent) {
            //  this.getAllPlannings();

            setTimeout(() => {
                this.progress = true;
                return this._adminService.getConnectedPlanning().subscribe(
                    (data: any) => {
                        this.plannings = data
                        if (data && data.length > 0) {
                            this.plannings.forEach((element: any) => {
                                this.events.push({
                                    start: element.planning.dateDebut,
                                    end: element.planning.dateFin,
                                    title: element.planning.titre,
                                    description: element.planning.description
                                })
                            })
                        }
                        this.progress = false;
                        this.calendarOptions = {
                            events: this.events,
                            views: {
                                timeGridWeek: {
                                    allDaySlot: false,
                                    slotDuration: '00:15',
                                    slotLabelInterval: '01:00'
                                }
                            },
                            locale: 'fr',
                            initialView: 'dayGridMonth',

                            dateClick: this.handleDateClick.bind(this),
                            eventClick: (event) => {
                                if (this.currentDeviceName != 'mobile' && this.currentDeviceName != 'tablet') {
                                    console.log(event)
                                    this.clickedEvent = event
                                    console.log(this.clickedEvent)
                                    this.show = true
                                }
                            },
                        };
                    }
                );
            }, 2200);
            setTimeout(() => {
                this.calendarOptions = {
                    events: this.events,
                    views: {
                        timeGridWeek: {
                            allDaySlot: false,
                            slotDuration: '00:15',
                            slotLabelInterval: '01:00'
                        }
                    },
                    locale: 'fr',
                    initialView: 'dayGridMonth',

                    dateClick: this.handleDateClick.bind(this),
                    eventClick: (event) => {
                        if (this.currentDeviceName != 'mobile' && this.currentDeviceName != 'tablet') {
                            console.log(event)
                            this.clickedEvent = event
                            console.log(this.clickedEvent)
                            this.show = true
                        }
                    },
                };

            }, 2500);
        }
        this.progress = false;

        if (this.client) {
            setTimeout(() => {
                return this._adminService.getAgentPlanning().subscribe(
                    (data: any) => {
                        this.plannings = data;
                        this.plannings.forEach((element: any) => {
                            // console.log('elements ==>', element);
                            this.event.push({
                                start: element.dateDebut,
                                end: element.dateFin,
                                title: element.titre,
                                description: element.description
                            })
                        })
                    }
                )
            }, 2200);
            setTimeout(() => {
                this.calendarOptions = {
                    events: this.event,
                    views: {
                        timeGridWeek: {
                            allDaySlot: false,
                            slotDuration: '00:15',
                            slotLabelInterval: '01:00'
                        }
                    },
                    locale: 'fr',
                    initialView: 'dayGridMonth',

                    dateClick: this.handleDateClick.bind(this),
                    eventClick: (event) => {
                        if (this.currentDeviceName != 'mobile' && this.currentDeviceName != 'tablet') {
                            console.log(event)
                            this.clickedEvent = event
                            console.log(this.clickedEvent)
                            this.show = true
                        }
                    },
                };
            }, 2500);
        }
        // console.log(new Date())

        //Appel de l'observable pour les données su planngings
        this._planningObervable.reloadUser.subscribe(
            (resolve) => {
                this.getEntreprise();
                this.getAllPlannings();
            }
        )

        if (this.userPlanning) {
            setTimeout(() => {
                let id = this.route.snapshot.params['id'];
                return this._adminService.getUserPlannings(id).subscribe(
                    (res: any) => {
                        this.plannings = res
                        // console.log(this.plannings)
                        this.plannings.forEach((element: any) => {
                            // console.log('elements ==>', element)
                            this.events.push({
                                start: element.planning.dateDebut,
                                end: element.planning.dateFin,
                                title: element.planning.titre,
                                description: element.planning.description
                            })
                        });
                    }
                )
            }, 2200);
        }

        // console.log('event interval ==>', this.events)


        if (this.userPlanning) {
            setTimeout(() => {
                let id = this.route.snapshot.params['id'];
                return this._adminService.getUserPlannings(id).subscribe(
                    (res: any) => {
                        this.plannings = res;
                        console.log(this.plannings)
                        this.plannings.forEach((element: any) => {
                            this.events.push({
                                start: element.planning.dateDebut,
                                end: element.planning.dateFin,
                                title: element.planning.titre,
                                description: element.planning.description
                            })
                        });
                    }
                )
            }, 2200);
            setTimeout(() => {
                this.calendarOptions = {
                    events: this.events,
                    views: {
                        timeGridWeek: {
                            allDaySlot: false,
                            slotDuration: '00:15',
                            slotLabelInterval: '01:00'
                        }
                    },
                    locale: 'fr',
                    initialView: 'dayGridMonth',

                    dateClick: this.handleDateClick.bind(this),
                    eventClick: (event: any) => {
                        if (this.currentDeviceName != 'mobile' && this.currentDeviceName != 'tablet') {
                            this.clickedEvent = event;
                            this.show = true
                        }
                    },
                };
            }, 2500);
        }
        if (this.sitePlanning) {
            setTimeout(() => {
                let id1 = this.route.snapshot.params['id1'];
                let id = this.route.snapshot.params['id'];
                this.progress = true;
                return this._siteService.getSitePlanning(id1, id).subscribe(
                    (res: any) => {
                        console.log('result====>', res)
                        this.plannings = res;
                        this.plannings.forEach((element: any) => {
                            // console.log('elements ==>', element)
                            this.events.push({
                                start: element.dateDebut,
                                end: element.dateFin,
                                title: element.titre,
                                description: element.description
                            })
                        });
                        this.progress = false;
                        this.initPlanning();
                    }
                )
            }, 2200);
            setTimeout(() => {
                this.initPlanning();
            }, 2500);
        }
        if (this.entreprise) {
            setTimeout(() => {
                this.emailAdmin = this.loginService.getUserInfos();
                this.employeService.searchByMail(this.emailAdmin).subscribe(
                    data => {
                        this.idEntreprise = data[0].entreprise.id;
                        this.getEntreprise();
                    }
                )
            })
        }


    }

    private _filterEmailClients(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.emailClients.filter(option => option.toLowerCase().includes(filterValue));
    }

    private _filterSiteClients(value: string): string[] {
        const filterValue = value.toLowerCase();
        if (filterValue.length > 0) {
            this.disabledSite = false;
        }
        return this.siteClients.filter(option => option.toLowerCase().includes(filterValue));
    }


    submitForm() {
        if (this.idSite !== 0) {
            if (this.userType === "admin") {
                this.router.navigate(['admin/site/' + this.idSite + '/add-planning']);
            } else if (this.userType === "gestionnaire") {
                this.router.navigate(['gestionnaire/site/' + this.idSite + '/add-planning']);
            }
        }
    }

    getClient(client: any) {
        console.log('click', client)
    }

    clientSelected(event: MatAutocompleteSelectedEvent) {
        let value = event.option.value;
        this.disabledSite = false;
        this.myControlSites.setValue('');
        this.fetchAllSites(value);
    }

    siteSelected(event: MatAutocompleteSelectedEvent) {
        let value = event.option.value;
        this.disabledSite = true;
        let site = this.sites.filter(site => {
            return site.nom === value;
        });
        this.idSite = site[0].id;
        console.log('site', site)
    }


    fetchAllClients() {
        this._adminService.get().subscribe(
            (data: any) => {
                this.clients = data;
                console.log(this.clients)
                this.clients.forEach(client => {
                    this.emailClients.push(client.uuser.email);
                });
            },
            err => {
                console.log(err);

            }
        )
    }

    fetchAllSites(value = null) {
        this.siteService.getAllSites().subscribe(
            (data: any) => {
                this.sites = data;
                this.sites = this.sites.filter(site => {
                    return site.client.uuser.email === value;
                });
                this.updateSites();
                console.log(this.sites);
            },
            (error) => {
                console.log(error);
            }
        )
    }

    updateSites() {
        this.siteClients = [];
        this.sites.forEach(site => {
            this.siteClients.push(site.nom);
        });
    }

    getAllPlannings() {
        this.progress = true;
        this.plannings.forEach((element: any) => {
            // console.log('elements ==>', element)
            this.events.push({
                start: element.planning.dateDebut,
                end: element.planning.dateFin,
                title: element.planning.titre,
                description: element.planning.description
            });
            this.progress = false;
            this.initPlanning();
        });
    }

    initPlanning() {
        this.calendarOptions = {
            events: this.events,
            views: {
                timeGridWeek: {
                    allDaySlot: false,
                    slotDuration: '00:15',
                    slotLabelInterval: '01:00'
                }
            },
            locale: 'fr',
            initialView: 'dayGridMonth',

            dateClick: this.handleDateClick.bind(this),
            eventClick: (event) => {
                if (this.currentDeviceName != 'mobile' && this.currentDeviceName != 'tablet') {
                    console.log(event)
                    this.clickedEvent = event
                    console.log(this.clickedEvent)
                    this.show = true
                }
            },
        };
    }

    ngAfterViewInit() {
        // console.log('plannings ==>', this.plannings?.planning)
    }

    handleDateClick(arg: any) {
        // alert('date click! ' + arg.dateStr)
    }

    getEntreprise() {
        if (this.idEntreprise) {
            this._service.getOne(this.idEntreprise).subscribe(
                data => {
                    this.entreprisePlanning = data
                    this.plannings = this.entreprisePlanning.plannings
                    this.entreprisePlanning.plannings.forEach((element: any) => {
                        // console.log('elements ==>', element)
                        this.events.push({
                            start: element.dateDebut,
                            end: element.dateFin,
                            title: element.titre,
                            description: element.description
                        })
                    });
                }
            )
            setTimeout(() => {
                this.calendarOptions = {
                    events: this.events,
                    views: {
                        timeGridWeek: {
                            allDaySlot: false,
                            slotDuration: '00:15',
                            slotLabelInterval: '01:00'
                        }
                    },
                    locale: 'fr',
                    initialView: 'dayGridMonth',

                    dateClick: this.handleDateClick.bind(this),
                    eventClick: (event: any) => {
                        if (this.currentDeviceName != 'mobile' && this.currentDeviceName != 'tablet') {
                            console.log(event)
                            this.clickedEvent = event
                            console.log(this.clickedEvent)
                            this.show = true
                        }
                    },
                };
            }, 2500);

        }
    }

    toggleView(state: any) {
        if (state) {
            document.getElementById('callenderRow')?.classList.remove('col-lg-9');
            document.getElementById('callenderRow')?.classList.add('col-lg-6');
        } else {
            this.detailsBox = false;
            document.getElementById('callenderRow')?.classList.remove('col-lg-6');
            document.getElementById('callenderRow')?.classList.add('col-lg-9');
        }
    }

    mainCourante(id: number) {
        this.router.navigate([`agent/${id}/main-courante`])
    }

    details(planning: any, i: any) {

        if (this.selected === i) {
            setTimeout(
                () => {
                    this.detailsBox = false;
                    this.selected = undefined;
                    this.toggleView(false);

                },
                500
            );
        } else {
            this.toggleView(true);
            setTimeout(() => {
                this.detailsBox = true;
                this.selected = i;

            }, 500)
        }
    }

    async getUserPlanning() {
        let id = this.route.snapshot.params['id'];
        this._adminService.getUserPlannings(id).subscribe(
            (res: any) => {
                this.plannings = res
                this.plannings.forEach((element: any) => {
                    this.events.push({
                        start: element.planning.dateDebut,
                        end: element.planning.dateFin
                    })
                });
            }
        )
    }

    getEvents() {
        this.calendarOptions.events = this.events;
    }

    back() {
        if (this.currentMonth === 0) {
            this.currentYear -= 1;
            this.currentMonth = 11;
        } else {
            this.currentMonth -= 1;
        }
        this.plannings = [];
    }

    next() {
        if (this.currentMonth === 11) {
            this.currentYear += 1;
            this.currentMonth = 0;
        } else {
            this.currentMonth += 1;
        }
        this.plannings = [];
    }
}
